/* eslint-disable new-cap */

/**
 * Module dependencies.
 */

import { Open_Sans as OpenSans, Raleway } from 'next/font/google';
import { colors } from './colors';
import { createCssVariables } from './utils/mixins';
import { css } from 'styled-components';
import { gradients } from './gradients';
import { media } from '@untile/react-core/styles/media';
import { transparentize } from './utils/colors';

/**
 * Export `openSansFont` configuration.
 */

export const openSansFont = OpenSans({
  style: 'normal',
  subsets: ['latin'],
  weight: ['400', '600']
});

/**
 * Export `ralewayFont` configuration.
 */

export const ralewayFont = Raleway({
  style: 'normal',
  subsets: ['latin'],
  weight: ['400', '500', '600']
});

/**
 * Export `rootVariables`.
 */

export const rootVariables = css`
  :root {
    /* Border */
    --border-radius-base: var(--space-xxs);
    --border-radius-xl: 99px;
    --border-radius-sm: 24px;
    --border-radius-xs: 16px;
    --border-radius-xxs: 8px;

    /* Colors */
    ${createCssVariables(colors, 'color')}
    --color-primary: var(--color-cyan400);
    --color-secondary: var(--color-slate500);
    --color-text: var(--color-slate50);
    --color-text-secondary: var(--color-slate900);
    --color-text-subtle: var(--color-slate300);
    --color-error: var(--color-red);
    --color-success: var(--color-green);

    /* Fonts */
    --font-family-open-sans: ${openSansFont.style.fontFamily};
    --font-family-raleway: ${ralewayFont.style.fontFamily};

    /* Gradients */
    ${createCssVariables(gradients, 'gradient')}

    /* Headings */
    --heading-max-width: 100%;

    ${media.min.md`
      --heading-max-width: 58%;
    `}

    /* Layout */
    --container-max-width: 976px;
    --gutter: var(--space-xxs);

    ${media.min.md`
      --gutter: var(--space-sm);
    `}

    @media (min-width: 1024px) {
      --container-max-width: 1020px;
    }

    @media (min-width: 1100px) {
      --container-max-width: 1096px;
    }

    @media (min-width: 1200px) {
      --container-max-width: 1196px;
    }

    @media (min-width: 1300px) {
      --container-max-width: 1296px;
    }

    @media (min-width: 1550px) {
      --container-max-width: 1536px;
    }

    /* Navbar */
    --logo-size: 130px;
    --navbar-height: 72px;

    ${media.min.sm`
      --logo-size: 164px;
      --navbar-height: 96px;
    `}

    /* Outline */
    --outline-color: ${transparentize('cyan400', 0.16)};
    --outline-offset: 3px;
    --outline-style: solid;
    --outline-width: 3px;

    /* Spaces */
    --section-space: 72px;
    --section-space-xl: 104px;
    --space-xxs: 8px;
    --space-xs: 16px;
    --space-sm: 24px;
    --space-ms: 32px;

    /* Swiper */
    --swiper-slide-gap: var(--space-xxs);
    --swiper-pagination-bottom: 0;
    --swiper-pagination-bullet-horizontal-gap: 8px;
    --swiper-pagination-bullet-inactive-color: var(--color-slate500);
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-color: var(--color-primary);
    --swiper-pagination-top: 0;

    /* Top Message */
    --top-message-height: 32px;

    /* Transitions */
    --transition-animation: cubic-bezier(0.4, 0, 0.2, 1);
    --transition-default: 350ms var(--transition-animation);
    --transition-fast: 150ms var(--transition-animation);
    --transition-slow: 500ms ease-in-out;

    /* Z-index */
    --z-index-hero: 10;
    --z-index-mobile-menu: 97;
    --z-index-modal-backdrop: 98;
    --z-index-modal: 99;
    --z-index-navbar: 97;
    --z-index-top-message: 97;
  }
`;
