/**
 * Export `routes`.
 */

export const routes = {
  carEvaluationBuyer: '/comprar-carro/verificacao-automovel',
  carEvaluationSeller: '/vender-carro/verificacao-automovel',
  contacts: '/contactos',
  faqs: '/faqs',
  faqsCategoria: '/faqs/:slug',
  goodPractices: '/boas-praticas-baterias-electricas',
  home: '/',
  legal: '/legal',
  legalDetails: '/legal/:slug',
  prices: '/precos',
  schedule: '/agendar',
  serviceConveniencia: '/servicos/conveniencia',
  serviceGarantias: '/servicos/garantias',
  serviceScan360: '/servicos/scan360',
  serviceScanDeBateria: '/servicos/scan-de-bateria'
} as const;

/**
 * Export `RouteName` type.
 */

export type RouteName = keyof typeof routes;
