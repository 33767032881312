/**
 * Module dependencies.
 */

import { AnimatePresence } from 'framer-motion';
import { ReactNode } from 'react';
import { createReactPortal } from 'src/core/utils/portals';
import { useBodyScroll } from 'src/hooks/use-body-scroll';

/**
 * Export `ModalProps` type.
 */

export type ModalProps = {
  isOpen: boolean;
  onRequesClose: () => void;
};

/**
 * `Props` type.
 */

type Props = ModalProps & {
  children: (props: ModalProps) => ReactNode;
  preventBodyScroll: boolean;
};

/**
 * Export `ModalPortal` component.
 */

export function ModalPortal(props: Props) {
  const { children, isOpen, onRequesClose, preventBodyScroll } = props;

  useBodyScroll({ off: preventBodyScroll });

  return createReactPortal(
    <AnimatePresence>{isOpen && children({ isOpen, onRequesClose })}</AnimatePresence>,
    'modal-root'
  );
}
