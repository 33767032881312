/**
 * Module dependencies.
 */

import { AnimatePresence, motion } from 'framer-motion';
import { Button } from 'src/components/core/buttons/button';
import { InputField } from 'src/components/core/forms/input/field';
import { ModalPortal, ModalProps } from 'src/components/core/modal/portal';
import { RawHtml } from '@untile/react-core/components/raw-html';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { Trans, useTranslation } from 'next-i18next';
import { axiosInstance } from 'src/core/utils/requests';
import { formatPhoneNumber } from 'src/core/utils/numbers';
import { media } from '@untile/react-core/styles/media';
import { routeResolve } from 'src/core/utils/routes';
import { transparentize } from 'src/styles/utils/colors';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSettings } from 'src/providers/settings';
import closeSvg from 'src/assets/svgs/close.svg';
import errorSvg from 'src/assets/svgs/error.svg';
import phoneSvg from 'src/assets/svgs/phone2.svg';
import sendSvg from 'src/assets/svgs/send.svg';
import styled from 'styled-components';
import successSvg from 'src/assets/svgs/success.svg';
import supportSvg from 'src/assets/svgs/support.svg';

/**
 * `FormSubmissionStatus` type.
 */

type FormSubmissionStatus = 'success' | 'error' | null;

/**
 * `Data` type.
 */

type Data = {
  phone: string;
};

/**
 * `Backdrop` styled component.
 */

const Backdrop = styled(motion.div)`
  background-color: rgba(2, 2, 6, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-modal-backdrop);
`;

/**
 * `WrapperModal` styled component.
 */

const WrapperModal = styled(motion.div)`
  --modal-call-me-box-shadow-desktop: 0 2.767px 2.214px 0 ${transparentize('black', 0.3)},
    0 6.65px 5.32px 0 ${transparentize('black', 0.04)}, 0 12.522px 10.017px 0 ${transparentize('black', 0.05)},
    0 22.336px 17.869px 0 ${transparentize('black', 0.07)}, 0 41.778px 33.422px 0 ${transparentize('black', 0.08)},
    0 100px 80px 0 ${transparentize('black', 0.11)};

  border-top-left-radius: var(--space-sm);
  border-top-right-radius: var(--space-sm);
  bottom: 0;
  box-shadow:
    0 -16px 40px 0 ${transparentize('black', 0.9)},
    0 4px 4px 0 ${transparentize('black', 0.25)};
  left: 50%;
  max-height: 100vh;
  max-width: 375px;
  overflow-y: auto;
  position: absolute;
  transform: translate3d(-50%, 0, 0);
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  ${media.min.sm`
    border-radius: var(--space-sm);
    bottom: 50%;
    box-shadow: var(--modal-call-me-box-shadow-desktop);
  `}
`;

/**
 * `Modal` styled component.
 */

const Modal = styled.div`
  align-items: center;
  background: var(--gradient-secondary--45-deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px var(--space-sm) 40px;
  z-index: var(--z-index-modal);
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  padding: 10px;
  position: absolute;
  right: var(--space-xxs);
  top: var(--space-xxs);
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ as: 'p', variant: 'bodyS' })`
  color: var(--color-slate50);
  text-align: center;

  a {
    border-bottom: 1px solid ${transparentize('slate300', 0.5)};

    :hover {
      border-bottom: 1px solid ${transparentize('cyan400', 0.5)};
      color: var(--color-primary);
    }
  }

  b {
    color: var(--color-slate300);
    font-weight: inherit;
  }
`;

/**
 * `Form` styled component.
 */

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  margin-bottom: 64px;
  width: 100%;
`;

/**
 * `PhoneField` styled component.
 */

const PhoneField = styled(InputField)`
  margin-bottom: 0;

  input {
    font-weight: 400;
    padding: 6px 47px !important;
    text-align: center;
  }
`;

/**
 * `MessageWrapper` styled component.
 */

const MessageWrapper = styled(motion.div)`
  text-align: center;
`;

/**
 * `MessageIcon` styled component.
 */

const MessageIcon = styled(Svg).attrs({ size: '38px' })`
  margin-bottom: var(--space-sm);
`;

/**
 * `Message` component.
 */

const Message = ({ action, type }: { action: () => void; type: 'error' | 'success' }) => {
  const { t } = useTranslation('common');

  return (
    <MessageWrapper animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 20 }} initial={{ opacity: 0, y: -20 }}>
      <MessageIcon icon={type === 'error' ? errorSvg : successSvg} />

      <Text as={'h5'} style={{ marginBottom: 'var(--space-xxs)' }} variant={'titleL'} weight={600}>
        {t(`common:modalCallMe.form.message.${type}.title`)}
      </Text>

      <Description as={'p'} style={{ color: 'var(--color-slate300)', marginBottom: '40px' }}>
        {t(`common:modalCallMe.form.message.${type}.description`)}
      </Description>

      <Button
        aria-label={t(`common:modalCallMe.form.message.${type}.action`)}
        colorTheme={'ghost'}
        onClick={action}
        size={'medium'}
        style={{ width: '100%' }}
      >
        {t(`common:modalCallMe.form.message.${type}.action`)}
      </Button>
    </MessageWrapper>
  );
};

/**
 * Export `CallMeModal` component.
 */

export const CallMeModal = ({ isOpen, onRequesClose }: ModalProps) => {
  const settings = useSettings();
  const isMobile = useBreakpoint(0, 'sm');
  const { t } = useTranslation('common');
  const [formSubmissionStatus, setFormSubmissionStatus] = useState<FormSubmissionStatus>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm<Data>({ mode: 'onSubmit', shouldFocusError: false });

  const handleOnSubmit = useCallback((formData: Data) => {
    setIsSubmitting(true);

    axiosInstance
      .post('/call-me', formData)
      .then(() => {
        setFormSubmissionStatus('success');
        setIsSubmitting(false);
      })
      .catch(() => {
        setFormSubmissionStatus('error');
        setIsSubmitting(false);
      });
  }, []);

  const handleOnClose = useCallback(() => {
    reset();
    setFormSubmissionStatus(null);
    setIsSubmitting(false);
    onRequesClose();
  }, [onRequesClose, reset]);

  return (
    <ModalPortal isOpen={isOpen} onRequesClose={handleOnClose} preventBodyScroll={isOpen}>
      {() => (
        <Backdrop animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
          <WrapperModal
            animate={{ opacity: 1, y: isMobile ? 0 : '50%' }}
            exit={{ opacity: 0, y: isMobile ? 100 : -100 }}
            initial={{ opacity: 0, x: '-50%', y: isMobile ? 100 : -100 }}
            onClick={event => {
              event.stopPropagation();
            }}
          >
            <Modal>
              <CloseButton aria-label={'Close'} onClick={handleOnClose}>
                <Svg icon={closeSvg} size={'20px'} />
              </CloseButton>

              <AnimatePresence>
                {formSubmissionStatus === 'success' && <Message action={handleOnClose} type={'success'} />}

                {formSubmissionStatus === 'error' && (
                  <Message action={() => setFormSubmissionStatus(null)} type={'error'} />
                )}

                {formSubmissionStatus === null && (
                  <>
                    <Svg icon={supportSvg} size={'38px'} style={{ marginBottom: 'var(--space-sm)' }} />

                    <Text style={{ marginBottom: 'var(--space-xxs)' }} variant={'titleL'} weight={600}>
                      {t('common:modalCallMe.title')}
                    </Text>

                    <Description style={{ color: 'var(--color-slate300)', marginBottom: '40px' }}>
                      {t('common:modalCallMe.description')}
                    </Description>

                    <Form onSubmit={handleSubmit(handleOnSubmit)}>
                      <PhoneField
                        control={control}
                        disabled={isSubmitting}
                        icon={phoneSvg}
                        mask={'999 999 999'}
                        name={'phone'}
                        placeholder={t('common:modalCallMe.form.inputPlaceholder')}
                        rules={{
                          required: t('common:modalCallMe.form.inputRuleRequired')
                        }}
                      />

                      <Button
                        aria-label={t('common:modalCallMe.form.submit')}
                        disabled={isSubmitting}
                        icon={sendSvg}
                        isLoading={isSubmitting}
                        reverse
                        size={'medium'}
                        type={'submit'}
                      >
                        {t('common:modalCallMe.form.submit')}
                      </Button>
                    </Form>

                    <Text
                      style={{ color: 'var(--color-slate100)', marginBottom: 'var(--space-xs)' }}
                      variant={'titleM'}
                      weight={600}
                    >
                      {t('common:modalCallMe.moreInfo.title')}
                    </Text>

                    <Description
                      as={'div'}
                      style={{ color: 'var(--color-slate100)', marginBottom: 'var(--space-xxs)' }}
                    >
                      <Trans
                        components={[
                          <RouterLink
                            aria-label={t('common:modalCallMe.moreInfo.faqs')}
                            href={routeResolve('faqs')}
                            key={'faqs-link'}
                            target={'_blank'}
                          />
                        ]}
                        i18nKey={t('common:modalCallMe.moreInfo.faqs')}
                        transSupportBasicHtmlNodes
                      />
                    </Description>

                    <Description style={{ marginBottom: 'var(--space-xxs)' }}>
                      <RawHtml>
                        {t('common:modalCallMe.moreInfo.phone', {
                          formattedPhone: formatPhoneNumber(settings?.phone_number as string),
                          phone: settings?.phone_number
                        })}
                      </RawHtml>
                    </Description>

                    <Text
                      style={{ color: 'var(--color-slate300)', marginBottom: 'var(--space-sm)' }}
                      variant={'bodyXs'}
                    >
                      {t('common:modalCallMe.moreInfo.phoneNote')}
                    </Text>

                    <Description>
                      <Trans
                        components={[
                          <RouterLink
                            aria-label={t('common:modalCallMe.legal')}
                            href={process.env.NEXT_PUBLIC_LEGAL_URL as string}
                            key={'legal-link'}
                            target={'_blank'}
                          />
                        ]}
                        i18nKey={t('common:modalCallMe.legal')}
                        transSupportBasicHtmlNodes
                      />
                    </Description>
                  </>
                )}
              </AnimatePresence>
            </Modal>
          </WrapperModal>
        </Backdrop>
      )}
    </ModalPortal>
  );
};
