/**
 * Module dependencies.
 */

import { Context, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

/**
 * `HistoryProps` type.
 */

type HistoryProps = {
  previousPath: string | undefined;
};

/**
 * `Props` type
 */

type Props = {
  children: ReactNode;
};

/**
 * Export `HistoryContext`.
 */

export const HistoryContext: Context<HistoryProps> = createContext({} as HistoryProps);

/**
 * Export `useHistory` hook.
 */

export function useHistory(): HistoryProps {
  return useContext(HistoryContext);
}

/**
 * Export `HistoryProvider` component.
 */

export const HistoryProvider = ({ children }: Props) => {
  const [previousPath, setPreviousPath] = useState<string | undefined>(undefined);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setPreviousPath(router.asPath);
    }

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    }
  }, [router.asPath, router.events]);

  return (
    <HistoryContext.Provider value={{ previousPath }}>
      {children}
    </HistoryContext.Provider>
  );
};
