/**
 * Module dependencies.
 */

import { ErrorMessage, FormControl, FormGroup, Label } from 'src/components/core/forms/styles';
import { FormInputProps } from 'src/types/form';
import { HTMLProps, ReactNode, forwardRef, useMemo } from 'react';
import { Svg } from 'src/components/core/svg';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

/**
 * Export `InputProps` interface.
 */

export type InputProps = HTMLProps<HTMLInputElement> &
  Omit<FormInputProps<HTMLInputElement>, 'label'> & {
    icon?: string;
    label?: ReactNode;
    mask?: string;
  };

/**
 * `StyledInput` styled component.
 */

const StyledInput = styled(FormControl)<Omit<InputProps, 'error' | 'isOptional' | 'label'>>`
  &[data-icon='true'] {
    padding: 6px 15px 6px 47px;
  }
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg).attrs({ size: 'var(--space-sm)' })<{ disabled?: boolean }>`
  color: var(--color-slate800);
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: color var(--transition-default);
  z-index: 1;

  :disabled {
    color: var(--color-slate500);
    pointer-events: none;
  }
`;

/**
 * Export `Input` component.
 */

export const Input = forwardRef((props: InputProps, ref: any) => {
  const { className, disabled, error, hasError, icon, id, isOptional, label, mask, type, ...rest } = props;

  const maskInputProps = useMemo(
    () =>
      mask
        ? {
            mask,
            maskPlaceholder: null
          }
        : {},
    [mask]
  );

  return (
    <FormGroup className={className}>
      {label && (
        <Label disabled={disabled} htmlFor={id} isOptional={isOptional}>
          {label}
        </Label>
      )}

      <div style={{ position: 'relative' }}>
        {icon && <Icon disabled={disabled} icon={icon} />}

        <StyledInput
          {...(mask && {
            as: InputMask,
            ...maskInputProps
          })}
          data-error={hasError}
          data-icon={!!icon}
          disabled={disabled}
          id={id}
          ref={ref}
          type={type ?? 'text'}
          {...rest}
        />
      </div>

      {hasError && <ErrorMessage disabled={disabled}>{error}</ErrorMessage>}
    </FormGroup>
  );
});

/**
 * `Input` display name.
 */

Input.displayName = 'Input';
