/**
 * Module dependencies
 */

import { ButtonHTMLAttributes, DetailedHTMLProps, ElementType, PropsWithoutRef, forwardRef } from 'react';
import { Loading } from 'src/components/core/loading';
import { Svg } from 'src/components/core/svg';
import { buttonThemes } from './button-theme-config';
import { ifProp, switchProp } from 'styled-tools';
import { setButtonLinkProps } from 'src/core/utils/links';
import styled from 'styled-components';

/**
 * `buttonSizes`constant.
 */

const buttonSizes = {
  medium: {
    '--button-font-size': '16px',
    '--button-grid-gap': '6px',
    '--button-height': '40px',
    '--button-icon-size': '20px',
    '--button-letter-spacing': '0.032px',
    '--button-line-height': '24px',
    '--button-padding': '8px 20px',
    '--button-text-padding': '8px 0'
  },
  small: {
    '--button-font-size': '14px',
    '--button-grid-gap': '6px',
    '--button-height': '32px',
    '--button-icon-size': '16px',
    '--button-letter-spacing': '0.07px',
    '--button-line-height': '20px',
    '--button-padding': '6px 16px',
    '--button-text-padding': '6px 0'
  }
};

/**
 * Export `ButtonProps` type.
 */

export type ButtonProps = PropsWithoutRef<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    as?: ElementType;
    colorTheme?: 'ghost' | 'minimal' | 'outline' | 'outlineDark' | 'outlineDarkened' | 'solid' | 'text';
    hideCursor?: boolean;
    href?: string;
    icon?: string;
    isLoading?: boolean;
    rel?: string;
    reverse?: boolean;
    size?: 'small' | 'medium';
    stretch?: boolean;
    target?: string;
  }
>;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button
  .withConfig({
    shouldForwardProp: prop => !['colorTheme', 'icon', 'isLoading', 'reverse', 'size', 'stretch'].includes(prop)
  })
  .attrs(setButtonLinkProps)<ButtonProps & { hasIcon: boolean }>`
  ${switchProp('colorTheme', buttonThemes)}
  ${switchProp('size', buttonSizes)}


  -webkit-tap-highlight-color: transparent;
  all: unset;
  align-items: center;
  background: var(--button-background-color);
  border-radius: 999px;
  border: var(--button-border-size) solid var(--button-border-color);
  box-sizing: border-box;
  box-shadow: var(--button-box-shadow);
  color: var(--button-text-color);
  cursor: pointer;
  display: inline-flex;
  font-family: var(--font-family-open-sans);
  font-size: var(--button-font-size);
  font-weight: 500;
  height: var(--button-height);
  justify-content: center;
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
  padding: var(--button-padding);
  position: relative;
  transition: var(--transition-default);
  transition-property: background-color, color, opacity;

  ${ifProp('stretch', 'width: 100%;')}

  ${ifProp(
    { colorTheme: 'text' },
    `
      --button-padding: var(--button-text-padding);

      outline-offset: var(--outline-offset);
      text-decoration: underline;
      text-decoration-color: var(--button-underline-color);
      text-decoration-thickness: 1px;
      text-underline-offset: 5px;
    `
  )}

  ${ifProp(
    { colorTheme: 'minimal' },
    `
      --button-padding: var(--button-text-padding);

      outline-offset: var(--outline-offset);
    `
  )}  

  ${ifProp({ colorTheme: 'ghost' }, 'backdrop-filter: blur(4px);')}

  :active{
    background: var(--button-active-background-color);
    color: var(--button-active-text-color);
  }

  :focus-visible {
    background: var(--button-focused-background-color);
    color: var(--button-focused-text-color);
  }

  :hover {
    background: var(--button-hover-background-color);
    color: var(--button-hover-text-color);
  }

  ${ifProp(
    'disabled',
    `
    background: var(--button-disabled-background-color);
    backdrop-filter: var(--button-disabled-backdrop-filter);
    color: var(--button-disabled-text-color);
    cursor: default;
    pointer-events: none;
  `
  )}
`;

/**
 * `Content` styled component.
 */

const Content = styled.span<
  Pick<ButtonProps, 'isLoading' | 'reverse' | 'stretch'> & {
    hasIcon: boolean;
  }
>`
  align-items: center;
  display: flex;
  flex-direction: ${ifProp('reverse', 'row-reverse', 'row')};
  grid-gap: ${ifProp('hasIcon', 'var(--button-grid-gap)', '0')};
  opacity: 1;
  transition: opacity var(--transition-default);

  ${ifProp(
    'stretch',
    `
    justify-content: space-between;
    width: 100%;
  `
  )}

  ${ifProp(
    'isLoading',
    `
    opacity: 0;
    pointer-events: none;
  `
  )}
`;

/**
 * Export `Button` component.
 */

export const Button = forwardRef<any, ButtonProps>((props: ButtonProps, ref: any) => {
  const {
    children,
    colorTheme = 'solid',
    disabled,
    icon,
    isLoading,
    reverse,
    size = 'small',
    stretch,
    ...rest
  } = props;

  return (
    <Wrapper
      colorTheme={colorTheme}
      disabled={isLoading || disabled}
      isLoading={isLoading}
      ref={ref}
      size={size}
      stretch={stretch}
      {...rest}
    >
      {isLoading && <Loading />}

      <Content hasIcon={!!icon} isLoading={isLoading} reverse={reverse} stretch={stretch}>
        {icon && <Svg icon={icon} size={'var(--button-icon-size)'} />}

        <span>{children}</span>
      </Content>
    </Wrapper>
  );
});

/**
 * `Button` display name.
 */

Button.displayName = 'Button';
