/**
 * Module dependencies.
 */

import { media } from '@untile/react-core/styles/media';
import styled from 'styled-components';

/**
 * Export `Grid` styled component.
 */

export const Grid = styled.div`
  display: grid;
  grid-template-areas: 'subgrid';
  grid-template-columns: 1fr;

  ${media.min.md`
    grid-template-areas: '. subgrid .';
    grid-template-columns: 1fr 10fr 1fr;
  `}

  &[data-has-offset-gutter='true'] {
    ${media.min.md`
      grid-template-columns: 0.75fr 10fr 0.75fr;
    `}
  }
`;

/**
 * Export `SubGrid` styled component.
 */

export const SubGrid = styled.div`
  grid-area: subgrid;
`;
