/**
 * Module dependencies.
 */

import { prop } from 'styled-tools';
import React, { DetailedHTMLProps, HTMLAttributes, PropsWithoutRef, forwardRef } from 'react';
import styled from 'styled-components';

/**
 * `ElementType` type.
 */

type ElementType = PropsWithoutRef<DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>>;

/**
 * Export `SvgProps` interface.
 */

export type SvgProps = ElementType & {
  className?: string;
  color?: string;
  icon: string;
  size?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span.withConfig({
  shouldForwardProp: prop => !['color', 'size'].includes(prop)
})<Omit<SvgProps, 'icon'>>`
  color: ${prop('color', 'currentColor')};
  display: inline-block;
  line-height: 0;
  position: relative;
  width: ${prop('size')};
`;

/**
 * Export `Svg` component.
 */

export const Svg = forwardRef<HTMLSpanElement, SvgProps>((props, ref) => {
  const { icon, ...rest } = props;
  const Component = icon;

  return (
    <Wrapper {...rest} ref={ref}>
      <Component />
    </Wrapper>
  );
});

/**
 * Display name.
 */

Svg.displayName = 'Svg';
