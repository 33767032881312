/**
 * Module dependencies.
 */

import { Badge } from 'src/components/core/badges/badge';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { footerMenuServices } from 'src/core/constants/footer';
import { useTranslation } from 'next-i18next';
import arrowRight from 'src/assets/svgs/arrow-right.svg';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-area: services;
  margin-bottom: var(--space-ms);
  padding: 0 var(--footer-gutter);
`;

/**
 * `StyledText` styled component.
 */

const StyledText = styled(Text).attrs({
  as: 'p',
  variant: 'labelS',
  weight: 600
})`
  background: var(--gradient-primary--0-deg);
  -webkit-background-clip: text;
  background-clip: text;
  color: var(--color-primary);
  margin-bottom: var(--space-xs);
  -webkit-text-fill-color: transparent;
`;

/**
 * `Link` styled component.
 */

const Link = styled(RouterLink)`
  align-items: center;
  color: var(--color-slate50);
  display: grid;
  grid-template-areas:
    'label badge'
    'additional-text additional-text';
  transition: color var(--transition-default);
  width: fit-content;

  :focus,
  :focus-visible,
  :hover {
    color: var(--color-slate200);
  }
`;

/**
 * Export `Services` component.
 */

export const Services = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledText>{t('common:footer.labels.services')}</StyledText>

      {footerMenuServices?.map(item => (
        <Link
          aria-label={t(`common:footer.labels.servicesLinks.${item.key}`)}
          href={item.url}
          key={item.key}
          style={{ marginBottom: item.mb }}
        >
          <Text
            style={{ gridArea: 'label' }}
            variant={item?.theme !== 'extra' ? 'bodyXl' : 'bodyM'}
            weight={item?.theme !== 'extra' ? 600 : 400}
            {...(item?.theme && { style: { marginRight: 10 } })}
          >
            {t(`common:footer.labels.servicesLinks.${item.key}`)}
          </Text>

          {item?.theme && (
            <div style={{ gridArea: 'badge' }}>
              <Badge style={{ marginRight: '12px' }}>{t(`common:footer.labels.badges.${item.theme}`)}</Badge>

              <Svg icon={arrowRight} size={'16px'} style={{ color: 'var(--color-cyan800)' }} />
            </div>
          )}

          {item?.additionalText && (
            <Text
              as={'p'}
              style={{ color: 'var(--color-slate300)', gridArea: 'additional-text' }}
              variant={'bodyS'}
              weight={400}
            >
              {t(`common:footer.labels.additionalText.${item.key}`)}
            </Text>
          )}
        </Link>
      ))}
    </Wrapper>
  );
};
