/**
 * Module dependencies.
 */

import { PrismicRichText } from 'src/components/prismic';
import { RichTextField } from '@prismicio/client';
import { colors } from 'src/styles/colors';
import { useEffect, useRef } from 'react';
import Head from 'next/head';
import Marquee from 'react-fast-marquee';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  text: RichTextField;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  background-color: var(--color-primary);
  color: var(--color-text-secondary);
  display: flex;
  font-family: var(--font-family-open-sans);
  font-size: 14px;
  font-weight: 600;
  height: var(--top-message-height);
  justify-content: center;
  left: 0;
  line-height: 20px;
  overflow: hidden;
  padding: var(--space-xxs) var(--space-sm);
  position: fixed;
  right: 0;
  top: 0;
  white-space: nowrap;
  z-index: var(--z-index-top-message);

  .rfm-marquee {
    gap: var(--space-sm);
  }
`;

/**
 * `RichTextComponent` component.
 */

const RichTextComponent = ({ text }: Props) => {
  const richTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (richTextRef.current) {
      const links = richTextRef.current.querySelectorAll('a');

      links.forEach(link => {
        link.setAttribute('tabindex', '-1');
      });
    }
  }, [text]);

  return (
    <div ref={richTextRef}>
      <PrismicRichText field={text} />
    </div>
  );
};

/**
 * Export `TopMessage` component.
 */

export const TopMessage = ({ text }: Props) => {
  return (
    <>
      <Head>
        <meta content={colors.cyan400} name={'theme-color'} />
      </Head>

      <Wrapper>
        <Marquee autoFill pauseOnClick play speed={20} style={{ gap: 'var(--space-xs)' }}>
          <RichTextComponent text={text} />
        </Marquee>
      </Wrapper>
    </>
  );
};
