/**
 * Module dependencies.
 */

import { FontProperties, setFontStyle } from 'src/styles/utils/typography';
import { ReactNode } from 'react';
import { ifProp, switchProp } from 'styled-tools';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

/**
 * `createFontStyle` util.
 */

const createFontStyle = (properties: FontProperties) => {
  if (isEmpty(properties) || !properties) {
    throw new Error('🚨 No font properties provided.');
  }

  return css`
    ${setFontStyle(properties)}

    margin: 0;
  `;
};

/**
 * `h1` styles.
 */

const h1 = {
  fontFamily: 'var(--font-family-raleway)',
  fontSize: 60,
  fontSizeMin: 36,
  fontWeight: 400,
  letterSpacing: -0.6,
  letterSpacingMin: -0.36,
  lineHeight: 68,
  lineHeightMin: 42
} as FontProperties;

/**
 * `h2` styles.
 */

const h2 = {
  fontFamily: 'var(--font-family-raleway)',
  fontSize: 36,
  fontSizeMin: 34,
  fontWeight: 400,
  letterSpacing: -0.36,
  letterSpacingMin: -0.34,
  lineHeight: 44,
  lineHeightMin: 38
} as FontProperties;

/**
 * `h3` styles.
 */

const h3 = {
  fontFamily: 'var(--font-family-raleway)',
  fontSize: 28,
  fontWeight: 400,
  letterSpacing: -0.28,
  lineHeight: 36,
  lineHeightMin: 34
} as FontProperties;

/**
 * `h4` styles.
 */

const h4 = {
  fontFamily: 'var(--font-family-raleway)',
  fontSize: 24,
  fontSizeMin: 22,
  fontWeight: 400,
  letterSpacing: -0.24,
  letterSpacingMin: -0.22,
  lineHeight: 32,
  lineHeightMin: 28
} as FontProperties;

/**
 * `titleL` styles.
 */

const titleL = {
  fontFamily: 'var(--font-family-raleway)',
  fontSize: 22,
  fontWeight: 400,
  lineHeight: 28
} as FontProperties;

/**
 * `titleM` styles.
 */

const titleM = {
  fontFamily: 'var(--font-family-raleway)',
  fontSize: 16,
  fontSizeMin: 14,
  fontWeight: 400,
  letterSpacing: 0.032,
  letterSpacingMin: 0.028,
  lineHeight: 20,
  lineHeightMin: 22
} as FontProperties;

/**
 * `titleXs` styles.
 */

const titleXs = {
  fontFamily: 'var(--font-family-raleway)',
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: 0.06,
  lineHeight: 16
} as FontProperties;

/**
 * `bodyXl` styles.
 */

const bodyXl = {
  fontFamily: 'var(--font-family-open-sans)',
  fontSize: 20,
  fontSizeMin: 18,
  fontWeight: 400,
  lineHeight: 32,
  lineHeightMin: 26
} as FontProperties;

/**
 * `bodyL` styles.
 */

const bodyL = {
  fontFamily: 'var(--font-family-open-sans)',
  fontSize: 18,
  fontSizeMin: 16,
  fontWeight: 400,
  lineHeight: 28,
  lineHeightMin: 24
} as FontProperties;

/**
 * `bodyM` styles.
 */

const bodyM = {
  fontFamily: 'var(--font-family-open-sans)',
  fontSize: 16,
  fontSizeMin: 14,
  fontWeight: 400,
  lineHeight: 24,
  lineHeightMin: 22
} as FontProperties;

/**
 * `bodyS` styles.
 */

const bodyS = {
  fontFamily: 'var(--font-family-open-sans)',
  fontSize: 14,
  fontSizeMin: 12,
  fontWeight: 400,
  letterSpacing: 0,
  letterSpacingMin: 0.06,
  lineHeight: 20,
  lineHeightMin: 18
} as FontProperties;

/**
 * `bodyXs` styles.
 */

const bodyXs = {
  fontFamily: 'var(--font-family-open-sans)',
  fontSize: 12,
  fontSizeMin: 10,
  fontWeight: 400,
  letterSpacing: 0.06,
  letterSpacingMin: 0.1,
  lineHeight: 16,
  lineHeightMin: 14
} as FontProperties;

/**
 * `bodyXxs` styles.
 */

const bodyXxs = {
  fontFamily: 'var(--font-family-open-sans)',
  fontSize: 10,
  fontSizeMin: 8,
  fontWeight: 400,
  letterSpacing: 0.06,
  letterSpacingMin: 0.1,
  lineHeight: 14,
  lineHeightMin: 12
} as FontProperties;

/**
 * `labelL` styles.
 */

const labelL = {
  fontFamily: 'var(--font-family-raleway)',
  fontSize: 18,
  fontSizeMin: 22,
  fontWeight: 400,
  lineHeight: 28
} as FontProperties;

/**
 * `labelM` styles.
 */

const labelM = {
  fontFamily: 'var(--font-family-raleway)',
  fontSize: 16,
  fontSizeMin: 14,
  fontWeight: 400,
  letterSpacing: 0.032,
  letterSpacingMin: 0.028,
  lineHeight: 24,
  lineHeightMin: 22
} as FontProperties;

/**
 * `labelS` styles.
 */

const labelS = {
  fontFamily: 'var(--font-family-raleway)',
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: 0.07,
  lineHeight: 20,
  lineHeightMin: 18
} as FontProperties;

/**
 * Variants themes.
 */

export const textStyles = {
  bodyL: createFontStyle(bodyL),
  bodyM: createFontStyle(bodyM),
  bodyS: createFontStyle(bodyS),
  bodyXl: createFontStyle(bodyXl),
  bodyXs: createFontStyle(bodyXs),
  bodyXxs: createFontStyle(bodyXxs),
  h1: createFontStyle(h1),
  h2: createFontStyle(h2),
  h3: createFontStyle(h3),
  h4: createFontStyle(h4),
  labelL: createFontStyle(labelL),
  labelM: createFontStyle(labelM),
  labelS: createFontStyle(labelS),
  titleL: createFontStyle(titleL),
  titleM: createFontStyle(titleM),
  titleXs: createFontStyle(titleXs)
} as const;

/**
 * `Props` type.
 */

type Props = {
  as?:
    | 'a'
    | 'address'
    | 'blockquote'
    | 'div'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'small'
    | 'strong'
    | 'sup'
    | 'i';
  block?: boolean;
  children: ReactNode;
  italic?: boolean;
  letterSpacing?: number;
  variant?: keyof typeof textStyles;
  weight?: 400 | 500 | 600;
};

/**
 * Export `Text` styled component.
 */

export const Text = styled.span<Props>`
  ${switchProp('variant', textStyles, textStyles.bodyM)}
  ${ifProp('block', 'display: block;')}
  ${ifProp('italic', 'font-style: italic;')}
  ${({ weight }) => weight && `font-weight: ${weight};`}
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing}px;`}
`;
