/**
 * Module dependencies.
 */

import { CreateClientConfig, enableAutoPreviews } from '@prismicio/next';
import { createClient } from '@prismicio/client';
import { routes } from 'src/core/constants/routes';

/**
 * Export `prismicRepoName`.
 */

export const prismicRepoName = process.env.NEXT_PUBLIC_PRISMIC_REPOSITORY_NAME as string;

/**
 * Export `createPrimsicClient`.
 */

export function createPrimsicClient({ previewData, req, ...config }: CreateClientConfig = {}) {
  const client = createClient(prismicRepoName, {
    fetchOptions:
      process.env.NODE_ENV === 'production'
        ? { cache: 'force-cache', next: { tags: ['prismic'] } }
        : { next: { revalidate: 5 } },
    routes: [
      {
        path: routes.home,
        type: 'home'
      },
      {
        path: routes.faqs,
        type: 'faqs'
      },
      {
        path: `${routes.faqs}/:uid`,
        type: 'faqs_categories'
      },
      {
        path: `${routes.legal}/:uid`,
        type: 'legal'
      }
    ],
    ...config
  });

  enableAutoPreviews({ client, previewData, req });

  return client;
}
