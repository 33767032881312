/**
 * Module dependencies.
 */

import { ComplaintsBook } from './complaints';
import { Copyright } from './copyright';
import { Grid, SubGrid } from 'src/components/core/layout/grid-content';
import { Legal } from './legal';
import { Location } from './location';
import { Logo } from './logo';
import { Pages } from './pages';
import { Section } from 'src/components/core/layout/section';
import { Services } from './services';
import { Support } from './support';
import { media } from '@untile/react-core/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import styled from 'styled-components';

/**
 * `StyledSection` styled component.
 */

const StyledSection = styled.footer`
  --footer-gutter: var(--space-ms);

  background-color: var(--color-slate800);
  padding: var(--section-space) 0 136px !important;

  ${media.min.ms`
    --footer-gutter: 0px;

    padding: var(--section-space) var(--space-ms) 136px !important;
  `}

  ${media.min.lg`
    padding-bottom: 42px !important;
  `}
`;

/**
 * `StyledSubGrid` styled component.
 */

const StyledSubGrid = styled(SubGrid)`
  ${media.min.ms`
    column-gap: var(--space-ms);
    display: grid; 
    grid-template-areas: 
      'logo logo'
      'services location'
      'pages location'
      'legal support'
      'rights complaints'; 
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: repeat(5, auto); 
  `}

  ${media.min.lg`
    column-gap: var(--space-ms);
    display: grid; 
    grid-template-areas: 
      'logo logo .'
      'services location support'
      'pages legal support'
      'rights complaints .'; 
    grid-template-columns: minmax(250px, 328px) 1fr minmax(250px, 328px); 
    grid-template-rows: repeat(4, auto); 
  `}
`;

/**
 * `MobileContent` component.
 */

const MobileContent = () => (
  <>
    <Services />

    <Pages />

    <Legal />

    <Support />

    <Location />

    <Logo />

    <Copyright />

    <ComplaintsBook />
  </>
);

/**
 * `DesktopContent` component.
 */

const DesktopContent = () => (
  <>
    <Logo />

    <Services />

    <Location />

    <Pages />

    <Legal />

    <Support />

    <Copyright />

    <ComplaintsBook />
  </>
);

/**
 * Export `Footer` component.
 */

export const Footer = () => {
  const isMobile = useBreakpoint(0, 'ms');

  return (
    <Section as={StyledSection}>
      <Grid>
        <StyledSubGrid>{isMobile ? <MobileContent /> : <DesktopContent />}</StyledSubGrid>
      </Grid>
    </Section>
  );
};
