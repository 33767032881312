/**
 * Module dependencies.
 */

import { RouterLink } from 'src/components/core/links/router-link';
import { media } from '@untile/react-core/styles/media';
import { routeResolve } from 'src/core/utils/routes';
import { textStyles } from 'src/components/core/text';
import { useSettings } from 'src/providers/settings';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-area: legal;
  grid-row-gap: var(--space-xs);
  grid-template-columns: 100%;
  margin-bottom: var(--space-ms);
  padding: 0 var(--footer-gutter);

  ${media.min.ms`
    align-content: end;
    align-items: end;
    margin-bottom: var(--section-space);
    padding-top: calc(var(--space-sm) * 2);
  `}
`;

/**
 * `Link` styled component.
 */

const Link = styled(RouterLink)`
  ${textStyles.bodyM}

  color: var(--color-slate300);
  display: block;
  font-weight: 400;
  transition: color var(--transition-default);
  white-space: nowrap;
  width: fit-content;

  :focus,
  :focus-visible,
  :hover {
    color: var(--color-primary);
  }
`;

/**
 * Export `Legal` component.
 */

export const Legal = () => {
  const settings = useSettings();
  const legalLinks = settings?.legalLinks;

  if (!legalLinks) {
    return null;
  }

  return (
    <Wrapper>
      <div style={{ display: 'grid', gap: 'var(--space-xs)' }}>
        {legalLinks?.map(({ slug, title }) => (
          <Link aria-label={title as string} href={routeResolve('legalDetails', { slug })} key={title}>
            {title}
          </Link>
        ))}
      </div>
    </Wrapper>
  );
};
