/**
 * Module dependencies.
 */

import { ChangeEvent, forwardRef } from 'react';
import { FormFieldProps } from 'src/types/form';
import { Input, InputProps } from './index';
import { assignRefs } from 'src/core/utils/refs';
import { useController } from 'react-hook-form';

/**
 * Export `InputFieldProps` interface.
 */

export type InputFieldProps = Omit<InputProps, 'hasError' | 'id'> &
  FormFieldProps<HTMLInputElement, InputProps['value']>;

/**
 * Export `InputField` component.
 */

export const InputField = forwardRef((props: InputFieldProps, forwardedRef: any) => {
  const { control, defaultValue = '', name, onChangeNormalizer, rules, type, ...rest } = props;

  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error }
  } = useController({
    control,
    defaultValue,
    name,
    rules
  });

  return (
    <Input
      {...rest}
      error={error?.message}
      hasError={!!error}
      id={name}
      onBlur={onBlur}
      onChange={
        !onChangeNormalizer
          ? onChange
          : event => onChangeNormalizer(event as ChangeEvent<HTMLInputElement>, onChange, value)
      }
      ref={assignRefs(forwardedRef, ref)}
      type={type ?? 'text'}
      value={value}
    />
  );
});

/**
 * `InputField` display name.
 */

InputField.displayName = 'InputField';
