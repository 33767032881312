/**
 * Export `contacts` constant.
 */

export const contacts = [
  {
    addressCountry: 'Portugal',
    addressLocality: 'Lisboa',
    addressRegion: 'Lisboa',
    postalCode: '1500-392',
    streetAddress: 'C-C. Colombo Av. Lusíada, Parque de Estacionamento Piso 1, Zona Verde',
    telephone: '+351 914 114 603'
  },
  {
    addressCountry: 'Portugal',
    addressLocality: 'Matosinhos',
    addressRegion: 'Porto',
    postalCode: '4450-013',
    streetAddress: 'Edifício Olympus Av. D. Afonso Henriques, 1462',
    telephone: '+351 914 114 407'
  }
];
