/**
 * Module dependencies.
 */

import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { media } from '@untile/react-core/styles/media';
import { routeResolve } from 'src/core/utils/routes';
import logoIcon from 'src/assets/svgs/logo.svg';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-area: logo;
  margin-bottom: var(--section-space);
  padding: 0 var(--footer-gutter);

  ${media.max.ms`
    margin-bottom: var(--space-ms);
    padding-top: 80px;
  `}
`;

/**
 * Export `Logo` component.
 */

export const Logo = ({ ...rest }) => (
  <Wrapper {...rest}>
    <RouterLink aria-label={'Home'} href={routeResolve('home')}>
      <Svg icon={logoIcon} size={'184px'} />
    </RouterLink>
  </Wrapper>
);
