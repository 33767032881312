/**
 * Module dependencies.
 */

import { RouterLink } from 'src/components/core/links/router-link';
import { footerMenuPages } from 'src/core/constants/footer';
import { media } from '@untile/react-core/styles/media';
import { textStyles } from 'src/components/core/text';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-area: pages;
  grid-row-gap: var(--space-xs);
  grid-template-columns: 100%;
  margin-bottom: var(--space-ms);
  padding: 0 var(--footer-gutter);

  ${media.min.ms`
    align-content: end;
    align-items: end;
    margin-bottom: 0;
  `}

  ${media.min.lg`
    margin-bottom: var(--section-space);
  `}
`;

/**
 * `Link` styled component.
 */

const Link = styled(RouterLink)`
  ${textStyles.bodyM}

  color: var(--color-slate50);
  display: block;
  font-weight: 400;
  transition: color var(--transition-default);
  white-space: nowrap;
  width: fit-content;

  :focus,
  :focus-visible,
  :hover {
    color: var(--color-slate200);
  }
`;

/**
 * Export `Pages` component.
 */

export const Pages = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div style={{ display: 'grid', gap: 'var(--space-xs)' }}>
        {footerMenuPages.map(item => (
          <Link aria-label={t(`common:footer.labels.pagesLinks.${item.key}`)} href={item.url} key={item.key}>
            {t(`common:footer.labels.pagesLinks.${item.key}`)}
          </Link>
        ))}
      </div>
    </Wrapper>
  );
};
