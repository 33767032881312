/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { CallMeModal } from 'src/components/call-me-modal';
import { Dropdown } from 'src/components/core/dropdown';
import { Grid, SubGrid } from 'src/components/core/layout/grid-content';
import { MenuItem, navbarMenu } from 'src/core/constants/menu';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { media } from '@untile/react-core/styles/media';
import { routeResolve } from 'src/core/utils/routes';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import caretIcon from 'src/assets/svgs/chevron-down.svg';
import logoIcon from 'src/assets/svgs/logo.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  hasTopMessage: boolean;
  hideBackground?: boolean;
};

/**
 * `NavbarLinkProps` type.
 */

type NavbarLinkProps = {
  menuItem: {
    items?: MenuItem[];
    key: MenuItem['key'];
    type?: 'dropdown';
    url?: MenuItem['url'];
  };
};

/**
 * `Nav` styled component.
 */

const Nav = styled.nav`
  color: var(--color-white);
  height: var(--navbar-height);
  left: var(--gutter);
  margin: 0 auto;
  max-width: var(--container-max-width);
  padding: var(--space-xs) var(--space-sm);
  position: fixed;
  right: var(--gutter);
  top: 0;
  z-index: var(--z-index-navbar);

  &[data-background='true'] {
    background: var(--gradient-secondary--45-deg);
    border-radius: 0 0 var(--space-sm) var(--space-sm);
  }

  &[data-top-message='true'] {
    top: var(--top-message-height);
  }

  ${media.min.sm`
    &[data-background='true'] {
      border-radius: 0 0 42px 42px;
    }
  `}

  ${media.min.md`
    padding: 22px 0;
  `}
`;

/**
 * `StyledSubGrid` styled component.
 */

const StyledSubGrid = styled(SubGrid)`
  align-items: center;
  display: grid;
  grid-template-areas: 'logo help';
  grid-template-columns: max-content max-content;
  justify-content: space-between;

  ${media.min.md`
    grid-column-gap: var(--space-xxs);
    grid-template-areas: 'logo menu help';
    grid-template-columns: 164px 1fr max-content;
  `}

  ${media.min.lg`
    grid-template-areas: 'logo menu help schedule';
    grid-template-columns: 164px 1fr repeat(2, max-content);
  `}
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)`
  grid-area: logo;
  line-height: 0;
  text-decoration: none;
`;

/**
 * `List` styled component.
 */

const List = styled.ul`
  align-items: center;
  display: flex;
  grid-area: menu;
  justify-self: center;

  li + li {
    margin-left: calc(var(--space-xxs) / 2);

    ${media.min.xl`
      margin-left: var(--space-xs);
    `}
  }
`;

/**
 * `MenuLink` styled component.
 */

const MenuLink = styled(RouterLink)`
  color: var(--color-white);
  cursor: pointer;
  font-family: var(--font-family-open-sans);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.032px;
  line-height: 24px;
  padding: var(--space-xs) var(--space-xxs);
  text-decoration: none;
  transition: color var(--transition-default);
  white-space: nowrap;

  &[data-dropdown='true'] {
    align-items: center;
    display: flex;
    gap: var(--space-xxs);
  }

  :focus,
  :focus-visible,
  :hover {
    color: var(--color-primary);
  }
`;

/**
 * `WrapperDropdownItems` styled component.
 */

const WrapperDropdownItems = styled.div`
  color: var(--color-white);
  position: relative;

  :focus-visible {
    color: var(--color-primary);
    outline: var(--outline-color) var(--outline-style) var(--outline-width);
  }

  :hover {
    color: var(--color-primary);
  }

  ${MenuLink} {
    &[data-dropdown='true'] {
      color: inherit;
    }
  }
`;

/**
 * `StyledDropdown` styled component.
 */

const StyledDropdown = styled(Dropdown)`
  display: flex;
  flex-direction: column;
  gap: var(--space-xxs);
  padding: var(--space-sm) var(--space-xs);

  ${MenuLink} {
    padding: 0 var(--space-xxs);
  }
`;

/**
 * `DropdownItems` component.
 */

const DropdownItems = ({ items, label }: { items: MenuItem[]; label: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('common');

  return (
    <WrapperDropdownItems
      aria-label={label}
      onBlur={() => setIsOpen(false)}
      onFocus={() => setIsOpen(true)}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      tabIndex={0}
    >
      <MenuLink as={'span'} data-dropdown={'true'}>
        {label}

        <Svg icon={caretIcon} size={'20px'} />
      </MenuLink>

      <StyledDropdown isOpen={isOpen}>
        {items.map(({ key, url }) => (
          <MenuLink aria-label={t(`common:navbar.${key}`)} href={url as string} key={key}>
            {t(`common:navbar.${key}`)}
          </MenuLink>
        ))}
      </StyledDropdown>
    </WrapperDropdownItems>
  );
};

/**
 * `NavbarLink` component.
 */

const NavbarLink = ({ menuItem }: NavbarLinkProps) => {
  const { t } = useTranslation('common');

  switch (menuItem.type) {
    case 'dropdown':
      return <DropdownItems items={menuItem.items as MenuItem[]} label={t(`common:navbar.${menuItem.key}`)} />;

    default:
      return (
        <MenuLink aria-label={t(`common:navbar.${menuItem.key}`)} href={menuItem.url as string}>
          {t(`common:navbar.${menuItem.key}`)}
        </MenuLink>
      );
  }
};

/**
 * Export `Navbar` component.
 */

export const Navbar = ({ hasTopMessage, hideBackground }: Props) => {
  const { t } = useTranslation('common');
  const isTablet = useBreakpoint(0, 'lg');
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Nav data-background={!hideBackground} data-top-message={hasTopMessage}>
      <Grid style={{ alignItems: 'center' }}>
        <StyledSubGrid>
          <LogoLink aria-label={'Home'} href={routeResolve('home')}>
            <Svg icon={logoIcon} size={'var(--logo-size)'} />
          </LogoLink>

          {!isTablet && (
            <List>
              {navbarMenu.map(menuItem => (
                <li key={menuItem.key}>
                  <NavbarLink menuItem={menuItem} />
                </li>
              ))}
            </List>
          )}

          <Button
            aria-label={t('common:labels.help')}
            colorTheme={'ghost'}
            onClick={() => setIsModalOpen(true)}
            style={{ gridArea: 'help' }}
          >
            {t('common:labels.help')}
          </Button>

          {!isTablet && (
            <Button
              aria-label={t('common:actions.schedule')}
              href={routeResolve('schedule')}
              style={{ gridArea: 'schedule' }}
            >
              {t('common:actions.schedule')}
            </Button>
          )}

          <CallMeModal isOpen={isModalOpen} onRequesClose={() => setIsModalOpen(false)} />
        </StyledSubGrid>
      </Grid>
    </Nav>
  );
};
