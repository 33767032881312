/**
 * Module dependencies.
 */

import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { useTranslation } from 'next-i18next';
import complaintsBookIcon from 'src/assets/svgs/complaints-book.svg';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-self: center;
  grid-area: complaints;
  padding: 0 var(--footer-gutter);
`;

/**
 * Export `ComplaintsBook` component.
 */

export const ComplaintsBook = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <RouterLink
        aria-label={t(`common:footer.complaintsBook.label`)}
        href={'https://www.livroreclamacoes.pt'}
        rel={'noopener noreferrer'}
        style={{ display: 'block', width: 'fit-content' }}
        target={'_blank'}
      >
        <Svg icon={complaintsBookIcon} size={'144px'} />
      </RouterLink>
    </Wrapper>
  );
};
