/**
 * Export `colors`.
 */

export const colors = {
  black: '#000000',
  cyan50: '#fdffff',
  cyan100: '#f5fdfe',
  cyan200: '#d0f6f9',
  cyan300: '#68e2eb',
  cyan400: '#00cddc',
  cyan500: '#02aabc',
  cyan600: '#04889c',
  cyan700: '#05657c',
  cyan800: '#07435c',
  cyan900: '#09203c',
  green: '#16a34a',
  red: '#f87171',
  slate50: '#f1f5f8',
  slate100: '#dce6ed',
  slate200: '#c6d6e2',
  slate300: '#9aacbc',
  slate400: '#6e8296',
  slate500: '#415870',
  slate600: '#152e4a',
  slate700: '#09203c',
  slate800: '#0a0a28',
  slate900: '#020206',
  white: '#ffffff'
};
