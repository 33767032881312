/**
 * Module dependencies.
 */

import { AppProps } from 'src/types/app';
import { DefaultSeo, LogoJsonLd, OrganizationJsonLd } from 'next-seo';
import { ErrorBoundary } from 'src/components/core/error/error-boundary';
import { GlobalStyle } from 'src/styles/global';
import { GlobalStyle as GlobalStyleUntile } from '@untile/react-core/styles/global';
import { HistoryProvider } from 'src/providers/history';
import { Layout } from 'src/components/layout';
import { PrismicPreview } from '@prismicio/next';
import { SettingsProvider } from 'src/providers/settings';
import { absoluteUrlResolve } from 'src/core/utils/url';
import { appWithTranslation } from 'next-i18next';
import { colors } from 'src/styles/colors';
import { contacts } from 'src/core/constants/contacts';
import { prismicRepoName } from 'src/prismic/client';
import { seoDefaultConfig } from 'src/core/constants/seo-default-config';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';

/**
 * Constants.
 */

const oneTrustScriptId = process.env.NEXT_PUBLIC_ONETRUST_SCRIPT_ID;
const googleTagManagerId = process.env.NEXT_PUBLIC_GTM_ID;

/**
 * `App` component.
 */

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (googleTagManagerId && window?.dataLayer) {
        window?.dataLayer.push({
          event: 'pageview',
          page: url
        });
      }

      if (oneTrustScriptId && window?.OneTrust) {
        window.OneTrust.initializeCookiePolicyHtml();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta charSet={'UTF-8'} />

        <meta content={'IE=edge'} httpEquiv={'X-UA-Compatible'} />

        <meta content={'width=device-width, initial-scale=1'} name={'viewport'} />

        <meta content={'true'} name={'HandheldFriendly'} />

        <link href={'/favicon.ico'} rel={'shortcut icon'} type={'image/x-icon'} />

        <link href={'/favicons/apple-touch-icon.png'} rel={'apple-touch-icon'} sizes={'180x180'} />

        <link href={'/favicons/favicon-32x32.png'} rel={'icon'} sizes={'32x32'} type={'image/png'} />

        <link href={'/favicons/favicon-16x16.png'} rel={'icon'} sizes={'16x16'} type={'image/png'} />

        <link href={'/site.webmanifest'} rel={'manifest'} />

        <link color={colors.slate300} href={'/favicons/safari-pinned-tab.svg'} rel={'mask-icon'} />

        <meta content={colors.white} name={'msapplication-TileColor'} />

        <meta content={colors.slate900} name={'theme-color'} />
      </Head>

      {oneTrustScriptId && googleTagManagerId && (
        <Script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line id-match
            __html: `
              // Define dataLayer and the gtag function.
              window.dataLayer = window.dataLayer || [];
              function gtag() { dataLayer.push(arguments); }

              // Default all consent categories to 'denied'
              gtag('consent', 'default', {
                ad_storage: "denied",
                analytics_storage: "denied",
                functionality_storage: "denied",
                personalization_storage: "denied",
                security_storage: "denied",
                ad_user_data: "denied",
                ad_personalization: "denied",
                'wait_for_update': 500
              });
              `
          }}
          id={'default-consent'}
          type={'text/javascript'}
        />
      )}

      {googleTagManagerId && (
        <Script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line id-match
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${googleTagManagerId}');
              `
          }}
          id={'google-tag-manager'}
          strategy={'beforeInteractive'}
        />
      )}

      {oneTrustScriptId && (
        <Script
          charSet={'UTF-8'}
          data-domain-script={oneTrustScriptId}
          id={'ot-sdk-stub-js'}
          src={'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'}
          strategy={'beforeInteractive'}
          type={'text/javascript'}
        />
      )}

      {oneTrustScriptId && (
        <Script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line id-match
            __html: `
              function OptanonWrapper() {}
              `
          }}
          id={'optanon'}
          strategy={'beforeInteractive'}
          type={'text/javascript'}
        />
      )}

      <GlobalStyleUntile />

      <GlobalStyle />

      <DefaultSeo {...seoDefaultConfig} />

      <LogoJsonLd logo={absoluteUrlResolve('/images/logo.jpg')} url={process.env.NEXT_PUBLIC_BASE_URL} />

      <OrganizationJsonLd
        address={contacts.map(({ addressCountry, addressLocality, postalCode, streetAddress }) => ({
          addressCountry,
          addressLocality,
          postalCode,
          streetAddress
        }))}
        contactPoint={contacts.map(({ telephone }) => ({
          areaServed: 'PT',
          contactType: 'customer service',
          telephone
        }))}
        legalName={seoDefaultConfig.openGraph?.siteName}
        logo={absoluteUrlResolve('/images/logo.jpg')}
        name={seoDefaultConfig.title as string}
        type={'Corporation'}
        url={process.env.NEXT_PUBLIC_BASE_URL}
      />

      <PrismicPreview repositoryName={prismicRepoName} />

      <ErrorBoundary>
        <HistoryProvider>
          <SettingsProvider value={pageProps.settings}>
            <Layout
              alternativeNavbar={pageProps.alternativeNavbar}
              hideFooter={pageProps.hideFooter}
              hideLayout={pageProps.hideLayout}
              hideMobileMenu={pageProps.hideMobileMenu}
              hideNavbarBackground={pageProps.hideNavbarBackground}
              hideTopMessage={pageProps.hideTopMessage}
            >
              <Component {...pageProps} />
            </Layout>
          </SettingsProvider>
        </HistoryProvider>
      </ErrorBoundary>
    </>
  );
}

/**
 * Export `App` component.
 */

export default appWithTranslation(App);
