/**
 * Module dependencies.
 */

import { Context, ReactNode, createContext, useContext } from 'react';
import { SettingsData } from 'src/hocs/with-settings';

/**
 * `SettingsProps` type.
 */

type SettingsProps = SettingsData['settings'];

/**
 * `Props` type
 */

type Props = {
  children: ReactNode;
  value: SettingsProps;
};

/**
 * Export `SettingsContext`.
 */

export const SettingsContext: Context<SettingsProps> = createContext({} as SettingsProps);

/**
 * Export `useSettings` hook.
 */

export function useSettings(): SettingsProps {
  return useContext(SettingsContext);
}

/**
 * Export `SettingsProvider` component.
 */

export const SettingsProvider = ({ children, value }: Props) => {
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
