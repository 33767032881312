/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Grid, SubGrid } from 'src/components/core/layout/grid-content';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { media } from '@untile/react-core/styles/media';
import { routeResolve } from 'src/core/utils/routes';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import logoIcon from 'src/assets/svgs/logo.svg';
import printSvg from 'src/assets/svgs/print.svg';
import shareSvg from 'src/assets/svgs/share.svg';
import styled from 'styled-components';

/**
 * `Nav` styled component.
 */

const Nav = styled.nav`
  background: var(--gradient-secondary--45-deg);
  border-radius: 0 0 var(--space-sm) var(--space-sm);
  color: var(--color-white);
  height: var(--navbar-height);
  left: var(--gutter);
  margin: 0 auto;
  max-width: var(--container-max-width);
  padding: var(--space-xs) var(--space-sm);
  position: fixed;
  right: var(--gutter);
  top: 0;
  z-index: var(--z-index-navbar);

  ${media.min.sm`
    border-radius: 0 0 42px 42px;
  `}

  ${media.min.md`
    padding: 22px 0;
  `}
`;

/**
 * `StyledSubGrid` styled component.
 */

const StyledSubGrid = styled(SubGrid)`
  align-items: center;
  display: grid;
  grid-template-areas: 'logo buttons';
  grid-template-columns: max-content max-content;
  justify-content: space-between;

  ${media.min.ms`
    grid-template-areas: 'logo buttons';
    grid-template-columns: 164px auto;
  `}
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)`
  grid-area: logo;
  line-height: 0;
  text-decoration: none;
`;

/**
 * Export `AlternativeNavbar` component.
 */

export const AlternativeNavbar = () => {
  const { t } = useTranslation('common');
  const isMobile = useBreakpoint(0, 'ms');

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Nav>
      <Grid style={{ alignItems: 'center' }}>
        <StyledSubGrid>
          <LogoLink aria-label={'Home'} href={routeResolve('home')}>
            <Svg icon={logoIcon} size={'var(--logo-size)'} />
          </LogoLink>

          <div style={{ display: 'flex', gap: 'var(--space-xxs)', gridArea: 'buttons' }}>
            {isClient && navigator?.share && (
              <Button
                aria-label={t('common:actions.share')}
                colorTheme={'ghost'}
                icon={shareSvg}
                onClick={() => {
                  navigator?.share({
                    url: window?.location?.href
                  });
                }}
                reverse
                size={'medium'}
              >
                {t('common:actions.share')}
              </Button>
            )}

            {isClient && !isMobile && (
              <Button
                aria-label={t('common:actions.print')}
                colorTheme={'ghost'}
                icon={printSvg}
                onClick={() => window?.print()}
                reverse
                size={'medium'}
              >
                {t('common:actions.print')}
              </Button>
            )}
          </div>
        </StyledSubGrid>
      </Grid>
    </Nav>
  );
};
