/**
 * Module dependencies.
 */

import { Container } from './container';
import { DetailedHTMLProps, ElementType, HTMLAttributes, PropsWithoutRef, ReactNode, forwardRef } from 'react';

import { useSettings } from 'src/providers/settings';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = PropsWithoutRef<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> & {
  as?: ElementType;
  children: ReactNode;
  className?: string;
  hasNavbarOffset?: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section<Pick<Props, 'hasNavbarOffset'>>`
  --section-padding-bottom: var(--section-space);
  --section-padding-top: var(--section-space);

  padding: var(--section-padding-top) var(--gutter) var(--section-padding-bottom);
`;

/**
 * Export `Section` component.
 */

export const Section = forwardRef<any, Props>(({ children, hasNavbarOffset, ...rest }: Props, ref) => {
  const settings = useSettings();
  const hasMarquee = settings?.promotional_header_message;

  return (
    <Wrapper
      {...rest}
      {...(hasNavbarOffset && {
        style: {
          ...rest.style,
          paddingTop: hasMarquee
            ? 'calc(var(--navbar-height) + var(--top-message-height) + var(--section-padding-top))'
            : 'calc(var(--navbar-height) + var(--section-padding-top))'
        }
      })}
      ref={ref}
    >
      <Container>{children}</Container>
    </Wrapper>
  );
});

/**
 * Display name.
 */

Section.displayName = 'Section';
