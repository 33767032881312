/**
 * Module dependencies.
 */

import { keyframes as keyframesCss } from 'styled-components';

/**
 * `spin` keyframe.
 */

const spin = keyframesCss`
  to {
    transform: rotate(360deg);
  }
`;

/**
 * `keyframes`.
 */

const keyframes = {
  spin
};

/**
 * Export `keyframes`.
 */

export default keyframes;
