/**
 * Export `gradients`.
 */

export const gradients = {
  'cyan--0-deg': 'linear-gradient(0deg, #0891b2 0%, #22d3ee 100%)',
  'cyan--180-deg': 'linear-gradient(180deg, #0891b2 0%, #22d3ee 100%)',
  error: 'linear-gradient(326deg, rgba(220, 38, 38, 0.40) 7.97%, rgba(248, 113, 113, 0.30) 97.66%)',
  'primary--0-deg': 'linear-gradient(0deg, #00a7dc 0%, #00cddc 48.5%, #01dccf 100%)',
  'primary--15-deg': 'linear-gradient(325deg, #00A7DC 16.5%, #00cddc 48.2%, #01dccf 80.24%)',
  'primary--15-deg--10':
    'linear-gradient(325deg, rgba(0, 167, 220, 0.10) 16.5%, rgba(0, 205, 220, 0.10) 48.2%, rgba(1, 220, 207, 0.10) 80.24%)',
  'primary--15-deg--20':
    'linear-gradient(325deg, rgba(0, 167, 220, 0.20) 16.5%, rgba(0, 205, 220, 0.20) 48.2%, rgba(1, 220, 207, 0.20) 80.24%)',
  'primary--270-deg': 'linear-gradient(270deg, #00a7dc 0%, #01dccf 100%)',
  'secondary--0-deg': 'linear-gradient(0deg, #0a0a28 0%, #094649 100%)',
  'secondary--15-deg': 'linear-gradient(326deg, #0a0a28 12.37%, #094649 75.29%)',
  'secondary--45-deg': 'linear-gradient(326deg, #0a0a28 7.97%, #094649 97.66%)',
  'secondary--45-deg--transparent':
    'linear-gradient(326deg, rgba(10, 10, 40, 0.60) 7.97%, rgba(9, 70, 73, 0.50) 97.66%)',
  'tertiary--0-deg': 'linear-gradient(180deg, #0a1428 0%, #020206 100%)',
  'tertiary--45-deg': 'linear-gradient(45deg, #020206 14.64%, #0a1428 85.36%)'
};
