/**
 * Export `regexes`.
 */

export const regexes = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  fullName: /^\S+(\s+\S+)+$/,
  interpolateUrl: /:([\s\S]+?)\b/g,
  phone: /^(\+{0,1})(?:[0-9] ?){8,14}[0-9]$/
};
