/**
 * Module dependencies.
 */

import { DetailedHTMLProps, HTMLAttributes, PropsWithoutRef } from 'react';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import styled from 'styled-components';

/**
 * `IconLabelType` type.
 */

type IconLabelType = PropsWithoutRef<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;

/**
 * Export `IconLabelProps` type.
 */

export type IconLabelProps = {
  className?: string;
  icon?: string;
  label?: string;
  labelSize?: 12 | 14;
  shouldAnimate?: boolean;
  staggerMobile?: boolean;
};

/**
 * `Wraper` styled component.
 */

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: var(--space-xs);
`;

/**
 * `Label` styled component.
 */

const Label = styled(Text)`
  background: var(--gradient-primary--0-deg);
  -webkit-background-clip: text;
  background-clip: text;
  color: var(--color-primary);
  font-family: var(--font-family-open-sans);
  font-weight: 600;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
`;

/**
 * Export `IconLabel` component.
 */

export const IconLabel = ({
  icon,
  label,
  labelSize = 14,
  shouldAnimate,
  staggerMobile,
  ...rest
}: IconLabelType & IconLabelProps) => {
  const isMobile = useBreakpoint(0, 'ms');

  if (!icon && !label) return null;

  return (
    <Wrapper {...rest}>
      {icon && (
        <FadeInUp shouldAnimate={shouldAnimate} staggerMobile={staggerMobile} verticalOffset={20}>
          <Svg icon={icon} size={isMobile ? '40px' : '52px'} />
        </FadeInUp>
      )}

      {label && (
        <FadeInUp shouldAnimate={shouldAnimate} staggerMobile={staggerMobile} verticalOffset={20}>
          <Label variant={labelSize === 14 ? 'labelM' : 'labelS'}>{label}</Label>
        </FadeInUp>
      )}
    </Wrapper>
  );
};
