/**
 * Export `formatPhoneNumber` util.
 */

export function formatPhoneNumber(number?: string | number) {
  if (!number) {
    return '';
  }

  return String(number)
    ?.replace(/(\d{3})(?=\d)/g, '$1 ')
    .trim();
}
