/**
 * Module dependencies.
 */

import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';
import { PrismicRichText } from '@prismicio/react';
import { textStyles } from 'src/components/core/text';
import { transparentize } from 'src/styles/utils/colors';
import styled from 'styled-components';

/**
 * Export `PrismicNextImage`, `PrismicNextLink` and `PrismicRichText` components.
 */

export { PrismicNextImage, PrismicNextLink, PrismicRichText };

/**
 * Export `PrismicRichTextWrapper` styled component.
 */

export const PrismicRichTextWrapper = styled.div`
  h2 {
    ${textStyles.h2};
    margin: calc(var(--space-sm) * 2) 0 var(--space-sm);
  }

  h3 {
    ${textStyles.h3};
    margin: var(--space-ms) 0 var(--space-xs);
  }

  h4 {
    ${textStyles.h4};
    margin: var(--space-ms) 0 var(--space-xs);
  }

  h2,
  h3,
  h4,
  b {
    font-weight: 600;
  }

  a {
    color: var(--color-primary);
    text-decoration: underline;
    text-decoration-color: ${transparentize('slate300', 0.5)};
    text-decoration-thickness: 1px;
    text-underline-offset: 5px;
    transition: color var(--transition-default);

    :focus,
    :focus-visible,
    :hover {
      color: var(--color-cyan500);
      text-decoration-color: ${transparentize('cyan500', 0.5)};
    }
  }

  ul,
  ol {
    margin: var(--space-xs) 0;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  li {
    margin-left: var(--space-xs);
  }

  p {
    ${textStyles.bodyM};
  }
`;
