/**
 * Module dependencies
 */

import { transparentize } from 'src/styles/utils/colors';

/**
 * Export color theme keys.
 */

export const variants = ['ghost', 'minimal', 'outline', 'outlineDark', 'outlineDarkened', 'solid', 'text'] as const;

/**
 * Export `ColorTheme` type.
 */

export type ColorTheme = (typeof variants)[number];

/**
 * Export `buttonThemesConfig` constant.
 */

export const buttonThemesConfig: Record<
  ColorTheme,
  Record<'active' | 'disabled' | 'focused' | 'hover' | 'idle', Record<string, string>>
> = {
  ghost: {
    active: {
      backgroundColor: `var(--gradient-primary--15-deg--10)`,
      textColor: 'var(--color-text)'
    },
    disabled: {
      backdropFilter: 'blur(4px)',
      backgroundColor: transparentize('slate400', 0.1),
      textColor: 'var(--color-slate500)'
    },
    focused: {
      backgroundColor: `var(--gradient-primary--15-deg--10)`,
      textColor: 'var(--color-text)'
    },
    hover: {
      backgroundColor: `var(--gradient-primary--15-deg--20)`,
      textColor: 'var(--color-text)'
    },
    idle: {
      backgroundColor: `var(--gradient-primary--15-deg--10)`,
      textColor: 'var(--color-text)'
    }
  },
  minimal: {
    active: {
      backgroundColor: 'transparent',
      textColor: 'var(--color-cyan400)'
    },
    disabled: {
      backgroundColor: 'transparent',
      textColor: 'var(--color-slate300)'
    },
    focused: {
      backgroundColor: 'transparent',
      textColor: 'var(--color-text)'
    },
    hover: {
      backgroundColor: 'transparent',
      textColor: 'var(--color-cyan400)'
    },
    idle: {
      backgroundColor: 'transparent',
      textColor: 'var(--color-text)'
    }
  },
  outline: {
    active: {
      backgroundColor: `linear-gradient(var(--color-cyan100),var(--color-cyan100)) padding-box, ${transparentize(
        'cyan400',
        0.16
      )} border-box`,
      borderSize: '2px',
      textColor: 'var(--color-text-secondary)'
    },
    disabled: {
      backdropFilter: 'none',
      backgroundColor: `linear-gradient(var(--color-cyan100),var(--color-cyan100)) padding-box, ${transparentize(
        'slate400',
        0.1
      )} border-box`,
      borderSize: '2px',
      textColor: 'var(--color-slate500)'
    },
    focused: {
      backgroundColor: `linear-gradient(var(--color-cyan100),var(--color-cyan100)) padding-box, ${transparentize(
        'cyan400',
        0.16
      )} border-box`,
      borderColor: 'transparent',
      borderSize: '2px',
      textColor: 'var(--color-text-secondary)'
    },
    hover: {
      backgroundColor: `linear-gradient(var(--color-cyan100),var(--color-cyan100)) padding-box, var(--gradient-primary--15-deg) border-box`,
      borderSize: '2px',
      textColor: 'var(--color-cyan500)'
    },
    idle: {
      backgroundColor: `linear-gradient(var(--color-cyan100),var(--color-cyan100)) padding-box, var(--gradient-primary--15-deg) border-box`,
      borderColor: 'transparent',
      borderSize: '2px',
      textColor: 'var(--color-text-secondary)'
    }
  },
  outlineDark: {
    active: {
      backgroundColor: `linear-gradient(var(--color-slate900),var(--color-slate900)) padding-box, ${transparentize(
        'cyan400',
        0.16
      )} border-box`,
      borderSize: '2px',
      textColor: 'var(--color-text)'
    },
    disabled: {
      backdropFilter: 'none',
      backgroundColor: `linear-gradient(var(--color-slate900),var(--color-slate900)) padding-box, ${transparentize(
        'slate400',
        0.1
      )} border-box`,
      borderSize: '2px',
      textColor: 'var(--color-slate500)'
    },
    focused: {
      backgroundColor: `linear-gradient(var(--color-slate900),var(--color-slate900)) padding-box, ${transparentize(
        'cyan400',
        0.16
      )} border-box`,
      borderColor: 'transparent',
      borderSize: '2px',
      textColor: 'var(--color-text)'
    },
    hover: {
      backgroundColor: `linear-gradient(var(--color-slate900),var(--color-slate900)) padding-box, var(--gradient-primary--15-deg) border-box`,
      borderSize: '2px',
      textColor: 'var(--color-cyan500)'
    },
    idle: {
      backgroundColor: `linear-gradient(var(--color-slate900),var(--color-slate900)) padding-box, var(--gradient-primary--15-deg) border-box`,
      borderColor: 'transparent',
      borderSize: '2px',
      textColor: 'var(--color-text)'
    }
  },
  outlineDarkened: {
    active: {
      backgroundColor: `linear-gradient(var(--color-slate800),var(--color-slate800)) padding-box, ${transparentize(
        'cyan400',
        0.16
      )} border-box`,
      borderSize: '2px',
      textColor: 'var(--color-text)'
    },
    disabled: {
      backdropFilter: 'none',
      backgroundColor: `linear-gradient(var(--color-slate800),var(--color-slate800)) padding-box, ${transparentize(
        'slate400',
        0.1
      )} border-box`,
      borderSize: '2px',
      textColor: 'var(--color-slate500)'
    },
    focused: {
      backgroundColor: `linear-gradient(var(--color-slate800),var(--color-slate800)) padding-box, ${transparentize(
        'cyan400',
        0.16
      )} border-box`,
      borderColor: 'transparent',
      borderSize: '2px',
      textColor: 'var(--color-text)'
    },
    hover: {
      backgroundColor: `linear-gradient(var(--color-slate800),var(--color-slate800)) padding-box, var(--gradient-primary--15-deg) border-box`,
      borderSize: '2px',
      textColor: 'var(--color-cyan500)'
    },
    idle: {
      backgroundColor: `linear-gradient(var(--color-slate800),var(--color-slate800)) padding-box, var(--gradient-primary--15-deg) border-box`,
      borderColor: 'transparent',
      borderSize: '2px',
      textColor: 'var(--color-text)'
    }
  },
  solid: {
    active: {
      backgroundColor: `var(--gradient-cyan--180-deg)`,
      textColor: 'var(--color-text-secondary)'
    },
    disabled: {
      backdropFilter: 'blur(4px)',
      backgroundColor: transparentize('slate400', 0.1),
      textColor: 'var(--color-slate500)'
    },
    focused: {
      backgroundColor: `var(--gradient-cyan--0-deg)`,
      textColor: 'var(--color-text-secondary)'
    },
    hover: {
      backgroundColor: `var(--gradient-cyan--0-deg)`,
      textColor: 'var(--color-text-secondary)'
    },
    idle: {
      backgroundColor: `var(--gradient-primary--15-deg)`,
      boxShadow: `0px 6px 38px 0px ${transparentize('cyan400', 0.4)}`,
      textColor: 'var(--color-text-secondary)'
    }
  },
  text: {
    active: {
      backgroundColor: 'transparent',
      textColor: 'var(--color-text)',
      underlineColor: transparentize('slate300', 0.5)
    },
    disabled: {
      backdropFilter: 'none',
      backgroundColor: 'transparent',
      textColor: 'var(--color-slate300)',
      underlineColor: transparentize('slate600', 0.2)
    },
    focused: {
      backgroundColor: 'transparent',
      textColor: 'var(--color-text)',
      underlineColor: transparentize('slate300', 0.5)
    },
    hover: {
      backgroundColor: 'transparent',
      textColor: 'var(--color-cyan400)',
      underlineColor: 'var(--color-cyan500)'
    },
    idle: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textColor: 'var(--color-text)',
      underlineColor: transparentize('slate300', 0.5)
    }
  }
};

/**
 * `buttonTheme´ constant.
 */

export const buttonThemes = Object.entries(buttonThemesConfig).reduce(
  (previous, [themeName, config]) => ({
    ...previous,
    [themeName]: `
    --button-background-color: ${config.idle.backgroundColor};
    --button-border-size: ${config.idle.borderSize};
    --button-border-color: ${config.idle.borderColor};
    --button-box-shadow: ${config.idle.boxShadow};
    --button-text-color: ${config.idle.textColor};
    --button-underline-color: ${config.idle.underlineColor};
    
    --button-active-background-color: ${config.active.backgroundColor};
    --button-active-border-color: ${config.active.borderColor};
    --button-active-border-size: ${config.active.borderSize};
    --button-active-text-color: ${config.active.textColor};
    --button-active-underline-color: ${config.active.underlineColor};
    
    --button-disabled-backdrop-filter: ${config.disabled.backdropFilter};
    --button-disabled-background-color: ${config.disabled.backgroundColor};
    --button-disabled-border-color: ${config.disabled.borderColor};
    --button-disabled-border-size: ${config.disabled.borderSize};
    --button-disabled-text-color: ${config.disabled.textColor};
    --button-disabled-underline-color: ${config.disabled.underlineColor};
    
    --button-focused-background-color: ${config.focused.backgroundColor};
    --button-focused-border-color: ${config.focused.borderColor};
    --button-focused-border-size: ${config.focused.borderSize};
    --button-focused-text-color: ${config.focused.textColor};
    --button-focused-underline-color: ${config.focused.underlineColor};
    
    --button-hover-background-color: ${config.hover.backgroundColor};
    --button-hover-border-color: ${config.hover.borderColor};
    --button-hover-border-size: ${config.hover.borderSize};
    --button-hover-text-color: ${config.hover.textColor};
    --button-hover-underline-color: ${config.hover.underlineColor};
    `
  }),
  {}
);
