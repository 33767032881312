/**
 * Module dependencies.
 */

import { ifNotProp } from 'styled-tools';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  fluid?: boolean;
};

/**
 * Export `Container` styled component.
 */

export const Container = styled.div<Props>`
  margin: 0 auto;
  position: relative;
  width: 100%;

  ${ifNotProp(
    'fluid',
    `
    max-width: var(--container-max-width);
  `
  )}
`;
