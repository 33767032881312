/**
 * Module dependencies.
 */

import axios from 'axios';
import qs from 'qs';

/**
 * Export `axiosInstance`.
 */

export const axiosInstance = axios.create({
  baseURL: '/api',
  paramsSerializer: {
    serialize: params => qs.stringify(params)
  },
  withCredentials: true
});
