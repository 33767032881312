/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { CallMeModal } from 'src/components/call-me-modal';
import { Heading } from 'src/components/core/heading';
import { Text } from 'src/components/core/text';
import { formatPhoneNumber } from 'src/core/utils/numbers';
import { media } from '@untile/react-core/styles/media';
import { transparentize } from 'src/styles/utils/colors';
import { useSettings } from 'src/providers/settings';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import phoneIcon from 'src/assets/svgs/phone.svg';
import styled from 'styled-components';
import supportIcon from 'src/assets/svgs/support.svg';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-area: support;

  ${media.max.ms`
    margin-bottom: var(--space-ms);
    padding: var(--space-sm) var(--space-xxs) 0 var(--space-xxs);
  `}

  ${media.min.ms`
    margin-bottom: var(--section-space);
    max-width: 328px;
    padding-top: calc(var(--space-ms) + var(--space-xs));
  `}

  ${media.min.lg`
    padding-top: 0;
  `}
`;

/**
 * `Card` styled component.
 */

const Card = styled.div`
  background-color: ${transparentize('cyan800', 0.16)};
  border-radius: var(--border-radius-sm);
  margin-bottom: var(--space-xs);
  padding: var(--space-xxs);
`;

/**
 * `InnerCard` styled component.
 */

const InnerCard = styled.div`
  background-color: ${transparentize('cyan800', 0.16)};
  border-radius: var(--border-radius-xs);
  padding: var(--space-sm) var(--space-xs);
`;

/**
 * `StyledText` styled component.
 */

const StyledText = styled(Text).attrs({
  as: 'p',
  variant: 'bodyS'
})`
  color: var(--color-slate300);
  text-align: center;

  ${media.max.ms`
    text-align: center;
  `}
`;

/**
 * Export `Support` component.
 */

export const Support = () => {
  const settings = useSettings();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Wrapper>
      <Card>
        <div style={{ padding: 'var(--space-xs) var(--space-xs) var(--space-ms)' }}>
          <Heading
            asDescription={'p'}
            asTitle={'p'}
            description={t('common:footer.labels.support.mainDescription')}
            descriptionSize={14}
            descriptionTheme={'slate50'}
            icon={supportIcon}
            label={t('common:cards.support.title')}
            labelSize={14}
            shouldAnimate={false}
            style={{ marginBottom: 'var(--space-sm)' }}
            titleSize={14}
          />

          <Button
            aria-label={t('common:actions.callMe')}
            colorTheme={'outlineDarkened'}
            href={`tel:${settings?.phone_number}`}
            icon={phoneIcon}
            size={'medium'}
          >
            {formatPhoneNumber(settings?.phone_number as string)}
          </Button>
        </div>

        <InnerCard>
          <Text style={{ display: 'block', marginBottom: 'var(--space-sm)' }}>
            {t('common:footer.labels.support.description')}
          </Text>

          <Button
            aria-label={t('common:actions.callMe')}
            colorTheme={'ghost'}
            icon={phoneIcon}
            onClick={() => setIsModalOpen(true)}
            reverse
            size={'medium'}
          >
            {t('common:actions.callMe')}
          </Button>

          <CallMeModal isOpen={isModalOpen} onRequesClose={() => setIsModalOpen(false)} />
        </InnerCard>
      </Card>

      <StyledText>{t('common:footer.labels.support.phonePrice')}</StyledText>
    </Wrapper>
  );
};
