/**
 * Module dependencies.
 */

import { Text } from 'src/components/core/text';
import { transparentize } from 'src/styles/utils/colors';
import styled from 'styled-components';

/**
 * Export `Badge` styled component.
 */

export const Badge = styled(Text).attrs({ variant: 'labelS' })`
  background-color: ${transparentize('cyan400', 0.5)};
  border-radius: var(--border-radius-base);
  padding: 3px var(--space-xxs);
  width: fit-content;
`;
