/**
 * Module dependencies.
 */

import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(motion.div)`
  background-color: var(--color-slate900);
  border-radius: var(--border-radius-base);
  min-width: max-content;
  position: absolute;
  right: 0;
  top: 100%;
`;

/**
 * Export `Dropdown` component.
 */

export const Dropdown = ({ children, className, isOpen }: Props) => (
  <AnimatePresence>
    {isOpen && (
      <Wrapper
        animate={{ opacity: 1, y: 0 }}
        className={className}
        exit={{ opacity: 0, y: -20 }}
        initial={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        {children}
      </Wrapper>
    )}
  </AnimatePresence>
);
