/**
 * Module dependencies.
 */

import { DefaultSeoProps } from 'next-seo';
import { absoluteUrlResolve } from 'src/core/utils/url';
import packageJson from 'package.json';

/**
 * Export `openGraph`.
 */

export const openGraph = {
  images: [
    {
      alt: 'Verificar.pt',
      height: 600,
      url: absoluteUrlResolve('/images/share-image.jpg'),
      width: 1200
    }
  ],
  siteName: 'Verificar.pt',
  url: process.env.NEXT_PUBLIC_BASE_URL
};

/**
 * Export `seoDefaultConfig`.
 */

export const seoDefaultConfig: DefaultSeoProps = {
  additionalMetaTags: [
    {
      content: packageJson.version,
      name: 'version'
    },
    {
      content: 'Untile',
      name: 'author'
    }
  ],
  defaultTitle: 'Verificar.pt',
  openGraph,
  titleTemplate: '%s | Verificar.pt'
};
