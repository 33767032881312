/**
 * Module dependencies.
 */

import { Svg } from 'src/components/core/svg';
import { ifNotProp, ifProp } from 'styled-tools';
import keyframes from 'src/styles/keyframes';
import loadingSvg from 'src/assets/svgs/loading.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` interface.
 */

type Props = {
  disabled?: boolean;
  relative?: boolean;
  size?: string;
};

/**
 * Export `Loading` styled component.
 */

export const Loading = styled(Svg).attrs(({ size }) => ({
  icon: loadingSvg,
  size: size || '20px'
}))<Props>`
  opacity: ${ifProp('disabled', 0, 1)};
  pointer-events: none;
  transition: opacity var(--transition-default);

  ${ifNotProp(
    'relative',
    css`
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    `
  )}

  > svg {
    animation: ${keyframes.spin} 5s linear infinite;
  }
`;
