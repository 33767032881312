/**
 * Module dependencies.
 */

import { DetailedHTMLProps, HTMLAttributes, PropsWithoutRef, ReactNode } from 'react';
import { Svg } from 'src/components/core/svg';
import { ifProp } from 'styled-tools';
import { textStyles } from 'src/components/core/text';
import { transparentize } from 'src/styles/utils/colors';
import { useTranslation } from 'next-i18next';
import alertSvg from 'src/assets/svgs/alert.svg';
import styled from 'styled-components';

/**
 * Export `FormGroup` styled component.
 */

export const FormGroup = styled.div`
  --form-label-color: var(--color-slate50);
  --form-input-height: 40px;

  margin-bottom: var(--space-xs);
  position: relative;
`;

/**
 * `LabelWrapper` styled component.
 */

const LabelWrapper = styled.label<{ disabled?: boolean }>`
  ${textStyles.bodyS};

  align-items: center;
  color: var(--form-label-color);
  display: flex;
  font-weight: 500;
  gap: 2px;
  margin-bottom: 4px;
  transition: var(--transition-default);
  transition-property: color, opacity;

  :disabled {
    color: var(--color-slate500);
    opacity: 0.7;
    pointer-events: none;
  }
`;

/**
 * Export `Label` component.
 */

export const Label = ({
  children,
  disabled,
  isOptional
}: {
  children: ReactNode;
  disabled?: boolean;
  htmlFor: string;
  isOptional?: boolean;
} & PropsWithoutRef<DetailedHTMLProps<HTMLAttributes<HTMLLabelElement>, HTMLLabelElement>>) => {
  const { t } = useTranslation();

  return (
    <LabelWrapper disabled={disabled}>
      {children}

      {isOptional && <span style={{ color: 'var(--color-slate300)' }}>{t('common:forms.labels.optional')}</span>}
    </LabelWrapper>
  );
};

/**
 * Export `FormControl` styled component.
 */

export const FormControl = styled.input<any>`
  ${textStyles.labelM};

  appearance: none;
  background-color: var(--color-slate100);
  border: 2px solid var(--color-slate100);
  border-radius: var(--form-input-height);
  box-shadow: none;
  color: var(--color-slate800);
  font-weight: 600;
  height: var(--form-input-height);
  padding: 6px 15px;
  transition: var(--transition-default);
  transition-property: background-color, border-color, color;
  width: 100%;

  ::placeholder {
    color: var(--color-slate500);
    transition: color var(--transition-default);
  }

  :hover {
    background-color: var(--color-slate50);
    border-color: var(--color-cyan400);
  }

  :focus,
  :focus-visible {
    background-color: var(--color-slate50);
    border-color: var(--color-slate100);
    outline: none;
  }

  :disabled {
    background-color: ${transparentize('slate400', 0.1)};
    border-color: ${transparentize('slate600', 0.2)};
    color: var(--color-slate500);
    pointer-events: none;
  }

  &[data-error='true'] {
    border-color: var(--color-error);
  }

  ${ifProp(
    'value',
    `
    background-color: var(--color-slate100);
    border-color: var(--color-slate100);
  `
  )};
`;

/**
 * Export `ErrorMessageWrapper` styled component.
 */

export const ErrorMessageWrapper = styled.div<{ disabled?: boolean }>`
  ${textStyles.labelS}

  align-items: center;
  color: var(--color-error);
  display: flex;
  gap: 4px;
  padding-top: 4px;
  transition: color var(--transition-default);

  :disabled {
    color: var(--color-slate500);
    pointer-events: none;
  }
`;

/**
 * Export `ErrorMessage` component.
 */

export const ErrorMessage = ({ children, disabled }: { children: ReactNode; disabled?: boolean }) => (
  <ErrorMessageWrapper disabled={disabled}>
    <Svg icon={alertSvg} size={'20px'} />

    {children}
  </ErrorMessageWrapper>
);
