/**
 * Module dependencies.
 */

import { RouteName, routes } from 'src/core/constants/routes';
import { regexes } from 'src/core/constants/regexes';
import template from 'lodash/template';

/**
 * `Params` type.
 */

type Params = Record<string, string | number>;

/**
 * Export `routeResolve` util.
 */

export function routeResolve(routeName: RouteName, params?: Params): string {
  const route = routes[routeName as RouteName];

  return !params ? route : template(route, { interpolate: regexes.interpolateUrl })(params);
}
