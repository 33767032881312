/**
 * Module dependencies.
 */

import { createGlobalStyle } from 'styled-components';
import { rootVariables } from './variables';

/**
 * Export `GlobalStyle` component.
 */

export const GlobalStyle = createGlobalStyle`
  ${rootVariables}

  html {
    scroll-behavior: smooth;
  }

  body {
    background-color: var(--color-slate900);
    color: var(--color-text);
    font-family: var(--font-family-raleway);
  }

  .swiper-pagination {
    margin: var(--space-sm) 0 var(--space-xxs);
    position: relative;
  }

  a:focus-visible,
  button:focus-visible,
  input:focus-visible {
    outline: var(--outline-color) var(--outline-style) var(--outline-width);
  }
`;
