/**
 * Module dependencies.
 */

import { Text } from 'src/components/core/text';
import { media } from '@untile/react-core/styles/media';
import { routeResolve } from 'src/core/utils/routes';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  grid-area: location;
  padding: 0 var(--footer-gutter);

  ${media.max.ms`
    padding-top: var(--space-sm);
  `}

  ${media.min.ms`
    padding-left: var(--space-sm);
  `}

  ${media.min.lg`
    padding-left: 0;
  `}
`;

/**
 * `StyledText` styled component.
 */

const StyledText = styled(Text).attrs({
  as: 'p',
  variant: 'labelS',
  weight: 600
})`
  background: var(--gradient-primary--0-deg);
  -webkit-background-clip: text;
  background-clip: text;
  color: var(--color-primary);
  margin-bottom: var(--space-xs);
  -webkit-text-fill-color: transparent;
`;

/**
 * `StyledGrid` styled component.
 */

const StyledGrid = styled.div`
  display: grid;
  gap: var(--space-xxs);
  margin-bottom: var(--space-xs);

  ${media.min.ms`
    gap: var(--space-ms);
    margin-bottom: 0;
    max-width: 328px;
  `}

  ${media.min.xxl`
    grid-template-columns: 1fr 1fr;
    max-width: none;
  `}
`;

/**
 * `LocationWrapper` styled component.
 */

const LocationWrapper = styled.a`
  align-items: baseline;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: var(--space-xxs);

  ${media.min.ms`
    flex-direction: column;
  `}

  &[data-style='movel-unit'] {
    flex-direction: column;

    ${media.max.ms`
      padding-top: var(--space-xxs);
    `}
  }
`;

/**
 * `LocationName` styled component.
 */

const LocationName = styled(Text).attrs({ variant: 'bodyXl' })`
  color: var(--color-slate50);
  display: block;
`;

/**
 * `LocationCity` styled component.
 */

const LocationDescription = styled(Text).attrs({ variant: 'bodyM' })`
  color: var(--color-slate300);
  display: block;
`;

/**
 * Export `Location` component.
 */

export const Location = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledText>{t('common:footer.labels.locations')}</StyledText>

      <StyledGrid>
        <LocationWrapper
          aria-label={t('common:footer.labels.pagesLinks.contacts')}
          data-style={'movel-unit'}
          href={routeResolve('contacts')}
        >
          <LocationName>{t('common:contacts.movelUnit.name')}</LocationName>

          <LocationDescription>{t('common:contacts.movelUnit.footerDescription')}</LocationDescription>
        </LocationWrapper>
      </StyledGrid>
    </Wrapper>
  );
};
