/**
 * Module dependencies.
 */

import { HTMLMotionProps, motion, useInView } from 'framer-motion';
import { ReactNode, useRef } from 'react';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  delay?: number;
  shouldAnimate?: boolean;
  staggerMobile?: boolean;
  triggerInView?: boolean;
  verticalOffset?: number;
} & HTMLMotionProps<'div'>;

/**
 * Export `FadeInUp` component.
 */

export const FadeInUp = (props: Props) => {
  const { children, delay, shouldAnimate = true, staggerMobile, triggerInView, verticalOffset = 40, ...rest } = props;
  const ref = useRef(null);
  const isMobile = useBreakpoint(0, 'ms');
  const isInView = useInView(ref, { margin: isMobile ? '-112px 0px' : '-80px 0px', once: true });

  const variants = {
    animate: {
      opacity: 1,
      y: 0
    },
    initial: {
      opacity: 0,
      y: verticalOffset
    }
  };

  const transition = {
    duration: 0.5,
    ease: 'easeOut'
  };

  const animateWithoutController = (isMobile && !staggerMobile) || triggerInView;
  const animateWithController = ((isMobile && staggerMobile) || !isMobile) && !triggerInView;

  return shouldAnimate ? (
    <motion.div
      ref={ref}
      {...rest}
      {...(animateWithoutController && { animate: isInView ? 'animate' : 'initial' })}
      {...(animateWithoutController && { initial: 'initial' })}
      {...(animateWithoutController && { transition: { delay: triggerInView ? delay : 0, ...transition } })}
      {...(animateWithController && { transition: { ...transition } })}
      variants={variants}
    >
      {children}
    </motion.div>
  ) : (
    children
  );
};
