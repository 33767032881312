/**
 * Module dependencies.
 */

import { Component, ErrorInfo, ReactNode } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children?: ReactNode;
};

/**
 * `State` type.
 */

type State = {
  hasError: boolean;
};

/**
 * Export `ErrorBoundary` component.
 */

export class ErrorBoundary extends Component<Props, State> {
  /**
   * `state` type.
   */

  public state: State = {
    hasError: false
  };

  /**
   * `getDerivedStateFromError` method.
   */

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  /**
   * `componentDidCatch` method.
   */

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo); // eslint-disable-line no-console
  }

  /**
   * `render` method.
   */

  public render() {
    if (this.state.hasError) {
      return <h1>{'Sorry.. there was an error'}</h1>;
    }

    return this.props.children;
  }
}
