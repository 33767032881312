/**
 * Module dependencies.
 */

import { AlternativeNavbar } from './alternative-navbar';
import { Footer } from 'src/components/footer/index';
import { MobileMenu } from './mobile-menu';
import { Navbar } from './navbar';
import { ReactNode } from 'react';
import { SSGProps } from 'src/types/app';
import { TopMessage } from './top-message';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useSettings } from 'src/providers/settings';

/**
 * `Props` type.
 */

type Props = Pick<
  SSGProps,
  'alternativeNavbar' | 'hideFooter' | 'hideLayout' | 'hideMobileMenu' | 'hideNavbarBackground' | 'hideTopMessage'
> & {
  children: ReactNode;
};

/**
 * Export `Layout` component.
 */

export const Layout = (props: Props) => {
  const { alternativeNavbar, children, hideFooter, hideLayout, hideMobileMenu, hideNavbarBackground, hideTopMessage } =
    props;

  const settings = useSettings();
  const topMessage = settings?.promotional_header_message;
  const isMobile = useBreakpoint(0, 'lg');

  if (hideLayout) {
    return <>{children}</>;
  }

  return (
    <>
      {topMessage?.length > 0 && !hideTopMessage && <TopMessage text={topMessage} />}

      {alternativeNavbar ? (
        <AlternativeNavbar />
      ) : (
        <Navbar hasTopMessage={topMessage?.length > 0} hideBackground={hideNavbarBackground} />
      )}

      {children}

      {isMobile && !hideMobileMenu && <MobileMenu />}

      {!hideFooter && <Footer />}
    </>
  );
};
