/**
 * Module dependencies.
 */

import { AnimatePresence, motion } from 'framer-motion';
import { Badge } from 'src/components/core/badges/badge';
import { Button } from 'src/components/core/buttons/button';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from 'src/components/core/svg';
import { mobileFooterMenu, mobileMenu } from 'src/core/constants/menu';
import { routeResolve } from 'src/core/utils/routes';
import { textStyles } from 'src/components/core/text';
import { transparentize } from 'src/styles/utils/colors';
import { useBodyScroll } from 'src/hooks/use-body-scroll';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import arrowRight from 'src/assets/svgs/arrow-right-16.svg';
import closeIcon from 'src/assets/svgs/close.svg';
import menuIcon from 'src/assets/svgs/menu.svg';
import styled from 'styled-components';

/**
 * `variants` constant.
 */

const menuVariants = {
  closed: {
    height: '58px',
    transition: {
      height: { duration: 0.5 },
      when: 'afterChildren',
      width: { delay: 0.5, duration: 0.5 }
    },
    width: 'max-content'
  },
  open: {
    height: `calc(100dvh - 10vh)`,
    transition: {
      height: { delay: 0.5, duration: 0.5 },
      when: 'beforeChildren',
      width: { duration: 0.5 }
    },
    width: '100%'
  }
};

const backdropVariants = {
  closed: {
    opacity: 0,
    transition: { delay: 0.7, duration: 0.35 }
  },
  open: { opacity: 0.7 }
};

const bottomActionsVariants = {
  closed: {
    gap: 'var(--space-xxs)',
    transition: { delay: 0.5, duration: 0.5 },
    width: 'max-content'
  },
  open: {
    gap: 0,
    transition: { duration: 0.5 },
    width: 'calc(100% - 16px)'
  }
};

const menuButtonVariants = {
  closed: {
    transition: { delay: 0.5, duration: 0.5 },
    width: 'max-content'
  },
  open: {
    transition: { duration: 0.5 },
    width: '100%'
  }
};

const scheduleButtonVariants = {
  closed: {
    opacity: 1,
    transition: { delay: 0.5, duration: 0.35 },
    width: 'max-content'
  },
  open: {
    opacity: 0,
    transition: { duration: 0.35 },
    width: 0
  }
};

const contentVariants = {
  closed: {
    opacity: 0,
    transition: { duration: 0.5 }
  },
  open: {
    opacity: 1,
    transition: { delay: 0.5, duration: 0.5 }
  }
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  --mobile-menu-actions-height: 58px;

  bottom: var(--gutter);
  left: var(--gutter);
  pointer-events: none;
  position: fixed;
  right: var(--gutter);
  top: var(--gutter);
  z-index: var(--z-index-mobile-menu);
`;

/**
 * `Backdrop` styled component.
 */

const Backdrop = styled(motion.div)`
  background-color: var(--color-slate900);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

/**
 * `Menu` styled component.
 */

const Menu = styled(motion.div)`
  background-color: ${transparentize('slate900', 0.95)};
  border: 1px solid ${transparentize('white', 0.05)};
  border-radius: 28px;
  bottom: 40px;
  box-shadow:
    0 17px 80px 0 ${transparentize('black', 0.3)},
    0 10px 28px 0 ${transparentize('black', 0.2)};
  height: var(--mobile-menu-actions-height);
  left: 0;
  margin: 0 auto;
  min-height: var(--mobile-menu-actions-height);
  min-width: 324px;
  overflow: hidden;
  pointer-events: all;
  position: absolute;
  right: 0;
  width: max-content;
`;

/**
 * `BottomActions` styled component.
 */

const BottomActions = styled(motion.div)`
  align-items: center;
  bottom: var(--space-xxs);
  display: flex;
  gap: var(--space-xxs);
  justify-content: center;
  left: var(--space-xxs);
  position: absolute;
  right: var(--space-xxs);
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled(motion.div)`
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: var(--space-ms) var(--space-sm) calc(40px + var(--space-xxs));
  position: absolute;
  right: 0;
  top: 0;
`;

/**
 * `LinksGrid` styled component.
 */

const LinksGrid = styled.div`
  display: grid;
  gap: var(--space-xs);
  grid-template-columns: 1fr;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: calc(var(--space-ms) * 2);
  }
`;

/**
 * `Link` styled component.
 */

const Link = styled(RouterLink)`
  &[data-size='small'] {
    ${textStyles.bodyL}
  }

  &[data-size='large'] {
    ${textStyles.bodyXl}

    font-weight: 600;
  }

  align-items: center;
  color: var(--color-text);
  display: flex;
  font-family: var(--font-family-open-sans);
  gap: var(--space-xs);
  transition: color var(--transition-default);
  width: max-content;

  :hover,
  :focus,
  :focus-within {
    color: var(--color-slate200);
  }
`;

/**
 * `ArrowRight` styled component.
 */

const ArrowRight = styled(Svg).attrs({ icon: arrowRight, size: '16px' })`
  color: var(--color-slate50);
  opacity: 0.1;
`;

/**
 * Export `MobileMenu` component.
 */

export const MobileMenu = () => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);

  const [menuButton, setMenuButton] = useState({
    isAnimating: false,
    isOpen: false
  });

  const handleMenuState = () => {
    if (menuButton.isAnimating) return;

    setIsOpen(!isOpen);
    setMenuButton({ ...menuButton, isAnimating: true });

    setTimeout(() => {
      setMenuButton({ isAnimating: false, isOpen: !menuButton.isOpen });
    }, 500);
  };

  useBodyScroll({ off: isOpen });

  return (
    <Wrapper>
      <AnimatePresence>
        {isOpen && <Backdrop animate={'open'} exit={'closed'} initial={'closed'} variants={backdropVariants} />}
      </AnimatePresence>

      <Menu animate={isOpen ? 'open' : 'closed'} variants={menuVariants}>
        <AnimatePresence initial={false} mode={'wait'}>
          {isOpen && (
            <ContentWrapper animate={'open'} exit={'closed'} initial={'closed'} variants={contentVariants}>
              <LinksGrid>
                {mobileMenu.map(({ badgeKey, hasSpaceLeft, key, size, url }) => (
                  <Link
                    aria-label={t(`common:navbar:${key}`)}
                    data-size={size}
                    href={url}
                    key={key}
                    onClick={handleMenuState}
                    {...(hasSpaceLeft && { style: { marginLeft: 'var(--space-xs)' } })}
                  >
                    {t(`common:navbar:${key}`)}

                    {badgeKey && (
                      <>
                        <Badge style={{ backgroundColor: transparentize('cyan400', 0.5) }}>
                          {t(`common:badges:${badgeKey}`)}
                        </Badge>

                        <ArrowRight />
                      </>
                    )}
                  </Link>
                ))}
              </LinksGrid>

              <LinksGrid>
                {mobileFooterMenu.map(({ key, url }) => (
                  <Link aria-label={t(`common:navbar:${key}`)} href={url as string} key={key} onClick={handleMenuState}>
                    {t(`common:navbar:${key}`)}
                  </Link>
                ))}
              </LinksGrid>
            </ContentWrapper>
          )}
        </AnimatePresence>

        <BottomActions animate={isOpen ? 'open' : 'closed'} variants={bottomActionsVariants}>
          <motion.div animate={isOpen ? 'open' : 'closed'} variants={menuButtonVariants}>
            <Button
              aria-label={t(`common:actions:${menuButton.isOpen ? 'close' : 'menu'}`)}
              colorTheme={'ghost'}
              icon={menuButton.isOpen ? closeIcon : menuIcon}
              onClick={handleMenuState}
              reverse
              size={'medium'}
              stretch
            >
              {t(`common:actions:${menuButton.isOpen ? 'close' : 'menu'}`)}
            </Button>
          </motion.div>

          <motion.div animate={isOpen ? 'open' : 'closed'} variants={scheduleButtonVariants}>
            <Button
              aria-label={t('common:actions:schedule')}
              href={routeResolve('schedule')}
              onClick={handleMenuState}
              size={'medium'}
              style={{ whiteSpace: 'nowrap' }}
            >
              {t('common:actions:schedule')}
            </Button>
          </motion.div>
        </BottomActions>
      </Menu>
    </Wrapper>
  );
};
