/**
 * Module dependencies.
 */

import { Text } from 'src/components/core/text';
import { media } from '@untile/react-core/styles/media';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Text).attrs({ as: 'div', variant: 'bodyS' })`
  align-self: center;
  color: var(--color-slate300);
  grid-area: rights;
  padding: 0 var(--footer-gutter);

  ${media.max.ms`
    margin-bottom: var(--space-ms);
  `}
`;

/**
 * Export `Copyright` component.
 */

export const Copyright = () => {
  const { t } = useTranslation();

  return <Wrapper>{t(`common:footer.rights`, { date: new Date().getFullYear() })}</Wrapper>;
};
